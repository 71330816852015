import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AdAccount, AdAccountListRequest, APIError } from "../backendTypes";
import { DateTime } from "luxon";

const initialState = {
  adAccounts: [
    // {
    //   account_id: "552448722075289",
    //   currency: "EUR",
    //   last_scrape_date: "2024-04-21",
    //   last_updated_at: "2024-04-21T04:28:56.121Z",
    //   name: "Weinverkoster Ad Account",
    //   source: "facebook",
    //   time_zone: "Europe/Berlin",
    //   status: "success",
    // },
    // {
    //   account_id: "206607823326171",
    //   currency: "EUR",
    //   last_scrape_date: "2024-04-21",
    //   last_updated_at: "2024-04-21T08:38:56.121Z",
    //   name: "Rosental Ad Account",
    //   source: "google",
    //   time_zone: "Europe/Berlin",
    //   status: "success",
    // },
  ] as Array<AdAccount>,
  adAccountError: "",
};

export const listAdAccounts = createAsyncThunk<
  // Return type of the payload creator
  Array<AdAccount>,
  // First argument to the payload creator
  AdAccountListRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "adAccount/list",
  // Declare the type your function argument here:
  async (requestData: AdAccountListRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.get(
        `${requestData.backendUrl}/v1/account/list?limit=100000`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.items?.map((el: AdAccount) => ({
        ...el,
        status:
          DateTime.fromISO(el.last_updated_at).diffNow("days").days * -1 > 1
            ? "error"
            : "success",
      })) as Array<AdAccount>;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const adAccountsSlice = createSlice({
  name: "ad_accounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listAdAccounts.fulfilled, (state, { payload }) => {
      state.adAccounts = payload;
    });
    builder.addCase(listAdAccounts.rejected, (state, action) => {
      if (action.payload) {
        state.adAccountError = action.payload.error;
      } else {
        state.adAccountError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

// export const { login } = adAccountsSlice.actions;

export default adAccountsSlice.reducer;
