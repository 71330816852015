import { SetStateAction, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import { listAccounts, createAccount } from "./reducers/accounts";
import { Account, AccountCreateResponse, APIError } from "./backendTypes";
import TUtils from "./TUtils";
import { Entropy } from "entropy-string";

import { RootState, useAppDispatch as useDispatch } from "./store";
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { createApproval, listApprovals } from "./reducers/approvals";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Account;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "acid",
    numeric: false,
    disablePadding: false,
    label: "Account-ID",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "service_level",
    numeric: false,
    disablePadding: false,
    label: "Service-Level",
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Account
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Account) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function roleToName(role: number | string) {
  if (role === 255) return "admin";
  if (role === 128) return "master";
  return "user";
}

function slToName(role: number | string) {
  if (role === 255) return "enterprise";
  if (role === 128) return "premium";
  return "normal";
}

const headerStyle = {
  margin: "0.5em",
  paddingLeft: "8px",
};

const buttonStyle = {
  paddingTop: "2em",
  paddingBottom: "1em",
  paddingLeft: "16px",
};

const searchBoxStyle = {
  paddingLeft: "16px",
};

const searchInputStyle = {
  paddingLeft: "8px",
};

const adminApprovals = [
  "kytron::create_account",
  "kytron::create_customersite",
  "kytron::create_event",
  "kytron::read_events",
];
const rtsApprovalScope = "access_rts_dashboard";

export function generatePassword() {
  const entropy = new Entropy();
  return entropy.string();
}

function Accounts() {
  const navigate = useNavigate();

  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );

  const session = useSelector((state: RootState) => state.user.session);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);

  const handleUpdate = () => {
    dispatch(listAccounts({ session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
  };

  // component state
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Account>("email");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userMail, setUserMail] = useState("");
  const [userPassword, setUserPassword] = useState(generatePassword());
  const [userRole, setUserRole] = useState(2);
  const [userServiceLevel, setUserServiceLevel] = useState(2);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(accounts);

  // const [successDialogOpen, setSuccessDialogOpen] = useState(true);
  // const [errorDialogOpen, setErrorDialogOpen] = useState(true);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [addDlgOpen, setAddDlgOpen] = useState(false);

  const customFilterAccounts = () => {
    const lowerSearchText = searchText.toLocaleLowerCase();
    const baseItems = TUtils.filterArrayByString(accounts, searchText);
    const extItems = accounts.filter(
      (row) =>
        roleToName(row.role).toLocaleLowerCase() == lowerSearchText ||
        slToName(row.service_level).toLocaleLowerCase() == searchText ||
        lowerSearchText == (row.active ? "active" : "inactive")
    );
    const fullList = [];
    fullList.push(...baseItems);
    for (const item of extItems) {
      if (!fullList.includes(item)) fullList.push(item);
    }
    return fullList;
  };

  useEffect(() => {
    if (searchText.length !== 0) {
      // setRows(TUtils.filterArrayByString(accounts, searchText));
      setRows(customFilterAccounts());
      setPage(0);
    } else {
      setRows(accounts);
    }
  }, [accounts, searchText]);

  useEffect(() => {
    if (accounts.length == 0) {
      dispatch(listAccounts({ session, backendUrl }));
    }
  }, []);

  const handleClickOpen = () => {
    setUserPassword(generatePassword());
    setAddDlgOpen(true);
  };

  const handleClose = () => {
    setAddDlgOpen(false);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Account
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    acid: string | number
  ) => {
    console.log("Element clicked!");
    navigate(`/account/${acid}`);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onEmailInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setUserMail(event.target.value);
  };

  const onPasswordInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setUserPassword(event.target.value);
  };

  const onRoleChange = (event: { target: HTMLInputElement }) => {
    setUserRole(parseInt((event.target as HTMLInputElement).value, 10));
  };

  const onServiceLevelChange = (event: { target: HTMLInputElement }) => {
    setUserServiceLevel(parseInt((event.target as HTMLInputElement).value, 10));
  };

  const onSearchInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchText(event.target.value);
  };

  const handleCreateAccount = () => {
    dispatch(
      createAccount({
        email: userMail,
        password: TUtils.hash(userPassword),
        role: userRole,
        service_level: userServiceLevel,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: AccountCreateResponse) => {
        setAddDlgOpen(false);
        if (createResponse.created) {
          setSuccessMessage(
            `Account with email '${userMail}' and password '${userPassword}' has been generated'`
          );
          navigator.clipboard.writeText(
            `Email: '${userMail}'\nPassword: ${userPassword}`
          );
          dispatch(
            createApproval({
              acid: createResponse.acid,
              scope: rtsApprovalScope,
              rsid: undefined,
              rstype: 0,
              session,
              backendUrl,
            })
          )
            .unwrap()
            .then(() => {
              if (userRole == 128) {
                dispatch(
                  createApproval({
                    acid: createResponse.acid,
                    scope: "kytron::read_events",
                    rsid: undefined,
                    rstype: 0,
                    session,
                    backendUrl,
                  })
                )
                  .unwrap()
                  .then(() => handleUpdate());
              } else if (userRole == 255) {
                adminApprovals.forEach((approval) => {
                  dispatch(
                    createApproval({
                      acid: createResponse.acid,
                      scope: approval,
                      rsid: undefined,
                      rstype: 0,
                      session,
                      backendUrl,
                    })
                  )
                    .unwrap()
                    .then(() => handleUpdate());
                });
              } else {
                handleUpdate();
              }
            });
        } else {
          setErrorMessage(`Account with email '${userMail}' already exists!`);
        }
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to create account with email '${userMail}': ${err.error}`
        );
      });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <div>
      <div className="centered">
        <Box sx={{ width: "100%" }}>
          <Collapse in={successMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="success"
              onClose={() => {
                setSuccessMessage("");
              }}
            >
              {successMessage}
            </Alert>
          </Collapse>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Existing user accounts
            </Typography>
            <div className="hbox" style={searchBoxStyle}>
              <SearchIcon />
              <Input
                placeholder="Search"
                className="flex flex-1 mx-8"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={onSearchInputChange}
                style={searchInputStyle}
              />
            </div>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-label="simple table"
                size="medium"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                  {/** stableSort(rows, getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.acid)}
                          key={row.acid}
                        >
                          <TableCell component="th" scope="row">
                            {row.acid}
                          </TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">
                            {roleToName(row.role)}
                          </TableCell>
                          <TableCell align="left">
                            {slToName(row.service_level)}
                          </TableCell>
                          <TableCell align="left">
                            {row.active ? "active" : "inactive"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Button
              aria-label="delete"
              onClick={handleUpdate}
              startIcon={<UpdateIcon />}
              style={buttonStyle}
              color="secondary"
            >
              Refresh
            </Button>
            <Button
              aria-label="delete"
              onClick={handleClickOpen}
              startIcon={<AddCircleOutlineIcon />}
              style={buttonStyle}
            >
              Add User
            </Button>
            <Dialog open={addDlgOpen} onClose={handleClose}>
              <DialogTitle>Add user</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please provider the corresponding user info here:
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={userMail}
                  onChange={onEmailInputChange}
                />
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="password"
                  label="Password"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={userPassword}
                  onChange={onPasswordInputChange}
                />
                <FormControl>
                  <FormLabel id="role-type-label">Role</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="role-type-label"
                    name="role-type-group"
                    value={userRole}
                    onChange={onRoleChange}
                  >
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="User"
                    />
                    <FormControlLabel
                      value="128"
                      control={<Radio />}
                      label="Master"
                    />
                    <FormControlLabel
                      value="255"
                      control={<Radio />}
                      label="Admin"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <FormLabel id="sl-type-label">Service Level</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="sl-type-label"
                    name="sl-type-group"
                    value={userServiceLevel}
                    onChange={onServiceLevelChange}
                  >
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Standard"
                    />
                    <FormControlLabel
                      value="128"
                      control={<Radio />}
                      label="Premium"
                    />
                    <FormControlLabel
                      value="255"
                      control={<Radio />}
                      label="Enterprise"
                    />
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={handleCreateAccount}
                  disabled={userMail.length < 4}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default Accounts;
