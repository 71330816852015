import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import StoreIcon from "@mui/icons-material/Store";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SignalWifiStatusbar4BarIcon from "@mui/icons-material/SignalWifiStatusbar4Bar";
import { Chip, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import BusinessIcon from "@mui/icons-material/Business";
import { Sync } from "@mui/icons-material";

const buttonStyle = {
  paddingLeft: "1em",
  paddingRight: "1em",
};

const envObj: {
  [key: string]: { label: string; color: "primary" | "secondary" | "default" };
} = {
  production: { label: "Production", color: "primary" },
  development: { label: "Development", color: "secondary" },
  local: { label: "Local", color: "default" },
};

export default function TheresaAppBar() {
  // const backendUrl = useSelector(
  //   (state: RootState) => state.environment.backendUrl
  // );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const environment = backendUrl.startsWith("https://hive.tracify.ai")
    ? "production"
    : backendUrl.startsWith("https://devhive.tracify.ai")
    ? "development"
    : "local";
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Tooltip title="Login screen">
            <IconButton
              onClick={() => {
                dispatch({ type: "RESET_APP" });
                navigate("/login");
              }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="login"
              sx={{ mr: 2 }}
            >
              <LockIcon />
            </IconButton>
          </Tooltip>
          <Chip
            label={envObj[environment].label}
            color={envObj[environment].color}
          />
          <Typography variant="h1" component="div" sx={{ flexGrow: 1 }} />
          <Button
            component={Link}
            to="/organisations"
            color="inherit"
            startIcon={<BusinessIcon />}
            style={buttonStyle}
          >
            Organisations
          </Button>
          <Button
            component={Link}
            to="/stores"
            color="inherit"
            startIcon={<StoreIcon />}
            style={buttonStyle}
          >
            Stores
          </Button>
          <Button
            component={Link}
            to="/accounts"
            color="inherit"
            startIcon={<GroupIcon />}
            style={buttonStyle}
          >
            Accounts
          </Button>
          <Button
            component={Link}
            to="/status"
            color="inherit"
            startIcon={<SignalWifiStatusbar4BarIcon />}
            style={buttonStyle}
          >
            Status
          </Button>
          <Button
            component={Link}
            to="/syncing"
            color="inherit"
            startIcon={<Sync />}
            style={buttonStyle}
          >
            Syncing
          </Button>
          <Button
            component={Link}
            to="/orders"
            color="inherit"
            startIcon={<ShoppingCartIcon />}
            style={buttonStyle}
          >
            Orders
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
