import axios, { AxiosError } from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  APIError,
  Integration,
  IntegrationListRequest,
  ShopConnectorAPIError,
  ShopifyIntegration,
  ShopifyIntegrationCreateRequest,
  ShopifyIntegrationCreateResponse,
  ShopifyIntegrationDeleteRequest,
  ShopifyIntegrationGetRequest,
  ShopifyIntegrationUpdateRequest,
} from "../backendTypes";

const initialState = {
  integrations: [] as Array<Integration>,
  integrationError: "",
};

export const listIntegrations = createAsyncThunk<
  // Return type of the payload creator
  Array<Integration>,
  // First argument to the payload creator
  IntegrationListRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "integrations/list",
  // Declare the type your function argument here:
  async (requestData: IntegrationListRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.get(
        `${requestData.backendUrl}/v2/integrations/list?limit=100000&offset=0`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data?.items as Array<Integration>;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);
export const getShopifyIntegration = createAsyncThunk<
  // Return type of the payload creator
  ShopifyIntegration,
  // First argument to the payload creator
  ShopifyIntegrationGetRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "shopify-integration/get",
  // Declare the type your function argument here:
  async (requestData: ShopifyIntegrationGetRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.get(
        `${requestData.backendUrl}/v2/shopify/${requestData.id}/get`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data as ShopifyIntegration;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const createShopifyIntegration = createAsyncThunk<
  // Return type of the payload creator
  ShopifyIntegrationCreateResponse,
  // First argument to the payload creator
  ShopifyIntegrationCreateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "shopify-integration/create",
  // Declare the type your function argument here:
  async (requestData: ShopifyIntegrationCreateRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/v2/shopify/create`,
        {
          csid: requestData.csid,
          access_token: requestData.access_token,
          api_key: requestData.api_key,
          api_secret: requestData.api_secret,
          domain: requestData.domain,
          fingerprinting_active: requestData.fingerprinting_active,
          beta_active: requestData.beta_active,
          cookie_active_without_consent:
            requestData.cookie_active_without_consent,
          cookie_active_with_consent: requestData.cookie_active_with_consent,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data as ShopifyIntegrationCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const apiError = axiosError.response?.data as ShopConnectorAPIError;
        const firstErr = Array.isArray(apiError.detail)
          ? apiError.detail[0]
          : null;
        if (axiosError.response?.status === 404 && !firstErr) {
          return rejectWithValue({
            error: "Couldn't find integration with given ID",
          } as APIError);
        }
        return rejectWithValue({
          error: firstErr?.msg ?? axiosError.message,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const deleteShopifyIntegration = createAsyncThunk<
  // Return type of the payload creator
  null,
  // First argument to the payload creator
  ShopifyIntegrationDeleteRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "shopify-integrations/delete",
  // Declare the type your function argument here:
  async (requestData: ShopifyIntegrationDeleteRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/v2/shopify/${requestData.id}/delete`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 204) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return null;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const apiError = axiosError.response?.data as ShopConnectorAPIError;
        const firstErr = Array.isArray(apiError.detail)
          ? apiError.detail[0]
          : null;
        if (axiosError.response?.status === 404 && !firstErr) {
          return rejectWithValue({
            error: "Couldn't find integration with given ID",
          } as APIError);
        }
        return rejectWithValue({
          error: firstErr?.msg ?? axiosError.message,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const updateShopifyIntegration = createAsyncThunk<
  // Return type of the payload creator
  ShopifyIntegrationCreateResponse,
  // First argument to the payload creator
  ShopifyIntegrationUpdateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "shopify-integrations/update",
  // Declare the type your function argument here:
  async (requestData: ShopifyIntegrationUpdateRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/v2/shopify/${requestData.id}/update`,
        {
          csid: requestData.csid,
          access_token: requestData.access_token,
          api_key: requestData.api_key,
          api_secret: requestData.api_secret,
          domain: requestData.domain,
          fingerprinting_active: requestData.fingerprinting_active,
          beta_active: requestData.beta_active,
          cookie_active_without_consent:
            requestData.cookie_active_without_consent,
          cookie_active_with_consent: requestData.cookie_active_with_consent,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data as ShopifyIntegrationCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const apiError = axiosError.response?.data as ShopConnectorAPIError;
        const firstErr = Array.isArray(apiError.detail)
          ? apiError.detail[0]
          : null;
        if (axiosError.response?.status === 404 && !firstErr) {
          return rejectWithValue({
            error: "Couldn't find integration with given ID",
          } as APIError);
        }
        return rejectWithValue({
          error: firstErr?.msg ?? axiosError.message,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listIntegrations.fulfilled, (state, { payload }) => {
      state.integrations = payload;
    });
    builder.addCase(listIntegrations.rejected, (state, action) => {
      if (action.payload) {
        state.integrationError = action.payload.error;
      } else {
        state.integrationError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(createShopifyIntegration.rejected, (state, action) => {
      if (action.payload) {
        state.integrationError = action.payload.error;
      } else {
        state.integrationError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(updateShopifyIntegration.rejected, (state, action) => {
      if (action.payload) {
        state.integrationError = action.payload.error;
      } else {
        state.integrationError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(deleteShopifyIntegration.rejected, (state, action) => {
      if (action.payload) {
        state.integrationError = action.payload.error;
      } else {
        state.integrationError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

export default integrationsSlice.reducer;
