import { SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Stack } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch as useDispatch } from "./store";
import {
  changeBackendURL,
  changeChiefBackendURL,
  changeShopConnectorBackendURL,
  changeAdConnectorBackendURL,
} from "./reducers/environment";
import { login } from "./reducers/user";
import { useNavigate } from "react-router-dom";
import { APIError } from "./backendTypes";
import { useCookies } from "react-cookie";

const textStyle = {
  width: "100%",
};

const boxStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const errorMsgStyle = {
  textAlign: "center" as const,
  color: "rgb(177,3,24)",
  width: "100%",
  padding: "1em",
};

function Login() {
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies([
    "tracifyTheresaMail",
    "tracifyTheresaPwd",
  ]);
  const [email, setEmail] = useState(cookies.tracifyTheresaMail || "");
  const [password, setPassword] = useState(cookies.tracifyTheresaPwd || "");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeBackendURL((event.target as HTMLInputElement).value));

    if (event.target.value.startsWith("https://hive.tracify.ai")) {
      dispatch(changeChiefBackendURL("https://chief.tracify.ai/api"));
      dispatch(
        changeShopConnectorBackendURL("https://shopconnector.tracify.ai/api")
      );
      dispatch(
        changeAdConnectorBackendURL("https://adconnector.tracify.ai/api")
      );
    } else if (event.target.value.startsWith("https://devhive.tracify.ai")) {
      dispatch(changeChiefBackendURL("https://devchief.tracify.ai/api"));
      dispatch(
        changeShopConnectorBackendURL("https://devshopconnector.tracify.ai/api")
      );
      dispatch(
        changeAdConnectorBackendURL("https://devadconnector.tracify.ai/api")
      );
    } else {
      dispatch(changeChiefBackendURL("http://localhost:8000/api"));
      dispatch(changeShopConnectorBackendURL("http://localhost:8000/api"));
      dispatch(changeAdConnectorBackendURL("https://localhost:8000/api"));
    }
  };

  const handleLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setErrorMessage("");

    dispatch(login({ email, password, backendUrl }))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((err: APIError) => {
        setErrorMessage(`Unable to log into account '${email}': ${err.error}`);
      });
  };

  const onEmailInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    const val = event.target.value.toString();
    setEmail(val);
    setCookie("tracifyTheresaMail", val, { path: "/" });
  };

  const onPasswordInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    const val = event.target.value;
    setPassword(val);
    setCookie("tracifyTheresaPwd", val, { path: "/" });
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      onSubmit={handleLogin}
      noValidate
      autoComplete="on"
      style={boxStyle}
    >
      <Stack spacing={4}>
        <h1>Theresa Login</h1>
        {errorMessage && <p style={errorMsgStyle}>{errorMessage}</p>}
        <TextField
          required
          id="login-email"
          label="Email"
          value={email}
          onChange={onEmailInputChange}
          autoComplete="username"
          variant="standard"
          style={textStyle}
        />
        <TextField
          id="login-password-input"
          label="Password"
          type="password"
          value={password}
          onChange={onPasswordInputChange}
          autoComplete="current-password"
          variant="standard"
          style={textStyle}
        />
        <FormControl>
          <FormLabel id="login-type-label">Environment</FormLabel>
          <RadioGroup
            row
            aria-labelledby="login-type-label"
            name="login-type-group"
            value={backendUrl}
            onChange={handleChange}
          >
            <FormControlLabel
              value="https://hive.tracify.ai/v1/tracify/api"
              control={<Radio />}
              label="Production"
            />
            <FormControlLabel
              value="https://devhive.tracify.ai/v1/tracify/api"
              control={<Radio />}
              label="Development"
            />
            <FormControlLabel
              value="http://127.0.1.1:8282/v1/tracify/api"
              control={<Radio />}
              label="Local"
            />
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          endIcon={<LoginIcon />}
          type="submit"
          // onClick={handleLogin}
        >
          Login
        </Button>
      </Stack>
    </Box>
  );
}

export default Login;
