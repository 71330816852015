import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EnvironmentState {
  backendUrl: string;
  chiefBackendUrl: string;
  shopconnectorBackendUrl: string;
  adconnectorBackendUrl: string;
}

const initialState = {
  backendUrl: "https://hive.tracify.ai/v1/tracify/api",
  chiefBackendUrl: "https://chief.tracify.ai/api",
  shopconnectorBackendUrl: "https://shopconnector.tracify.ai/api",
  adconnectorBackendUrl: "https://adconnector.tracify.ai/api",
};

export const environmentSlice = createSlice({
  name: "environment",
  initialState,
  reducers: {
    changeBackendURL: (
      state: EnvironmentState,
      action: PayloadAction<string>
    ) => {
      state.backendUrl = action.payload;
    },
    changeChiefBackendURL: (
      state: EnvironmentState,
      action: PayloadAction<string>
    ) => {
      state.chiefBackendUrl = action.payload;
    },
    changeShopConnectorBackendURL: (
      state: EnvironmentState,
      action: PayloadAction<string>
    ) => {
      state.shopconnectorBackendUrl = action.payload;
    },
    changeAdConnectorBackendURL: (
      state: EnvironmentState,
      action: PayloadAction<string>
    ) => {
      state.adconnectorBackendUrl = action.payload;
    },
  },
});

export const {
  changeBackendURL,
  changeChiefBackendURL,
  changeShopConnectorBackendURL,
  changeAdConnectorBackendURL,
} = environmentSlice.actions;

export default environmentSlice.reducer;
