import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Account,
  AccountChangePasswordRequest,
  AccountChangePasswordResponse,
  AccountCreateRequest,
  AccountCreateResponse,
  AccountDeleteRequest,
  AccountDeleteResponse,
  AccountEnableRequest,
  AccountEnableResponse,
  AccountListRequest,
  AccountTypeChangeRequest,
  AccountTypeChangeResponse,
  APIError,
} from "../backendTypes";

const initialState = {
  accounts: [] as Array<Account>,
  accountError: "",
};

export const listAccounts = createAsyncThunk<
  // Return type of the payload creator
  Array<Account>,
  // First argument to the payload creator
  AccountListRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "accounts/list",
  // Declare the type your function argument here:
  async (requestData: AccountListRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.get(
        `${requestData.backendUrl}/account/list`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result.accounts as Array<Account>;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const createAccount = createAsyncThunk<
  // Return type of the payload creator
  AccountCreateResponse,
  // First argument to the payload creator
  AccountCreateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "accounts/create",
  // Declare the type your function argument here:
  async (requestData: AccountCreateRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/account/create`,
        {
          email: requestData.email,
          password: requestData.password,
          service_level: requestData.service_level,
          role: requestData.role,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as AccountCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const enableAccount = createAsyncThunk<
  // Return type of the payload creator
  AccountEnableResponse,
  // First argument to the payload creator
  AccountEnableRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "accounts/enable",
  // Declare the type your function argument here:
  async (requestData: AccountEnableRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/account/change`,
        {
          acid: requestData.acid,
          active: requestData.active,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as AccountEnableResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);
export const changeAccountPassword = createAsyncThunk<
  // Return type of the payload creator
  AccountChangePasswordResponse,
  // First argument to the payload creator
  AccountChangePasswordRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "accounts/change-password",
  // Declare the type your function argument here:
  async (requestData: AccountChangePasswordRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/account/change`,
        {
          acid: requestData.acid,
          password: requestData.password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as AccountChangePasswordResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const changeAccountType = createAsyncThunk<
  // Return type of the payload creator
  AccountTypeChangeResponse,
  // First argument to the payload creator
  AccountTypeChangeRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "accounts/changeType",
  // Declare the type your function argument here:
  async (requestData: AccountTypeChangeRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/account/change`,
        {
          acid: requestData.acid,
          service_level: requestData.service_level,
          role: requestData.role,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as AccountTypeChangeResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const deleteAccount = createAsyncThunk<
  // Return type of the payload creator
  AccountDeleteResponse,
  // First argument to the payload creator
  AccountDeleteRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "account/delete",
  // Declare the type your function argument here:
  async (requestData: AccountDeleteRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/account/delete`,
        {
          acid: requestData.acid,
          force: requestData.force,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as AccountDeleteResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listAccounts.fulfilled, (state, { payload }) => {
      state.accounts = payload;
    });
    builder.addCase(listAccounts.rejected, (state, action) => {
      if (action.payload) {
        state.accountError = action.payload.error;
      } else {
        state.accountError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(createAccount.rejected, (state, action) => {
      if (action.payload) {
        state.accountError = action.payload.error;
      } else {
        state.accountError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

// export const { login } = accountsSlice.actions;

export default accountsSlice.reducer;
