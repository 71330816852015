import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  Typography,
  Button,
  Alert,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Input,
  Checkbox,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { batch, useSelector } from "react-redux";
import {
  deleteStore,
  listStores,
  createStoreServerToken,
  updateStore,
} from "./reducers/stores";
import { RootState, useAppDispatch as useDispatch } from "./store";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";

import {
  Account,
  AdAccount,
  APIError,
  ApprovalCreateResponse,
  Organisation,
  OrganisationCreateResponse,
  ShopifyIntegration,
  Siteref,
  SiterefCreateResponse,
  Store,
  StoreServerTokenCreateResponse,
} from "./backendTypes";
import { useNavigate, useParams } from "react-router-dom";
import { listAccounts } from "./reducers/accounts";
import {
  createApproval,
  listApprovals,
  deleteApproval,
} from "./reducers/approvals";
import TUtils from "./TUtils";
import {
  createSiteref,
  deleteSiteref,
  listSiterefs,
} from "./reducers/siterefs";
import {
  listOrganisations,
  updateOrganisation,
} from "./reducers/organisations";
import {
  createShopifyIntegration,
  deleteShopifyIntegration,
  getShopifyIntegration,
  listIntegrations,
  updateShopifyIntegration,
} from "./reducers/integrations";
import {
  CheckCircleOutline,
  ErrorOutlineSharp,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { shopSystemOptions } from "./ClientStores";
import { listAdAccounts } from "./reducers/ad-accounts";
import { HeadCell } from "tableUtils";
import { DateTime } from "luxon";

const headerStyle = {
  // paddingTop: '2em',
  // paddingBottom: '1em',
  margin: "0.5em",
  paddingLeft: "8px",
};

const defaultStyle = {
  paddingLeft: "16px",
  margin: "0.5em",
  // marginLeft: '1em',
};

const searchBoxStyle = {
  paddingLeft: "16px",
};
const searchInputStyle = {
  paddingLeft: "8px",
};

function findStore(csid: string | undefined, stores: Array<Store>) {
  const c = stores.filter((store) => store.csid === csid);
  return c.length == 0 ? undefined : c[0];
}

function findAccount(
  acid: string | undefined,
  accounts: Array<Account> | undefined
) {
  if (accounts === undefined) return undefined;
  const a = accounts.filter((account) => account.acid === acid);
  return a.length == 0 ? undefined : a[0];
}

/**
 * Provides the main tracking code
 * @param {string} shopType Type of shop to create tracking code for
 * @param {boolean} enableFP Whether to enable fingerprinting or not
 * @param {string} trackingChannel Tracking channel to use.
 * @param {string} backendUrl URL for corresponding backend.
 * @param {string|undefined} csid ID of the store
 * @return {string} Tracking code for store
 */
const getTrackingCode = (
  shopType: string,
  enableFP: boolean,
  trackingChannel: string,
  backendUrl: string,
  csid: string | undefined
) => {
  if (csid === undefined) return "";

  let sv;
  let ch;
  if (trackingChannel === "production") {
    sv = enableFP ? `v2` : "";
    ch = "";
  } else {
    sv = enableFP ? `v2` : "";
    ch = "e";
  }

  let url;
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api")
    url = "https://scripting.tracify.ai";
  else if (backendUrl === "https://devhive.tracify.ai/v1/tracify/api")
    url = "https://devscripting.tracify.ai";
  else if (backendUrl === "http://127.0.1.1:8282/v1/tracify/api")
    url = "http://127.0.0.1/scripting";

  if (shopType === "shopify") {
    const href = `${url}/tracify${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
<link rel="preload" as="script" href="${href}">
<script async src="${href}"></script>`;
  } else if (shopType === "woocommerce") {
    const href = `${url}/tracifyw${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
<link rel="preload" as="script" href="${href}">
<script async src="${href}"></script>`;
  } else if (shopType === "shopware5") {
    const href = `${url}/tracifys${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
<link rel="preload" as="script" href="${href}">
<script async src="${href}"></script>`;
  } else if (shopType === "shopware6") {
    const href = `${url}/tracifys6${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
<link rel="preload" as="script" href="${href}">
<script async src="${href}"></script>`;
  } else if (shopType === "magento2_4") {
    const href = `${url}/tracifym2${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
<link rel="preload" as="script" href="${href}">
<script async src="${href}"></script>`;
  } else if (shopType === "custom") {
    const href = `${url}/tracifyc${sv}${ch}.js?csid=${csid}`;
    return `<link rel="preconnect" href="${url}">
<link rel="preload" as="script" href="${href}">
<script async src="${href}"></script>`;
  }

  return "";
};

const getCheckoutCode = (
  shopType: string,
  backendUrl: string,
  csid: string | undefined
) => {
  if (csid === undefined) return "";

  let url;
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api")
    url = "https://event.tracify.ai";
  else if (backendUrl === "https://devhive.tracify.ai/v1/tracify/api")
    url = "https://devevent.tracify.ai";
  else if (backendUrl === "http://127.0.1.1:8282/v1/tracify/api")
    url = "http://127.0.0.1/event";

  if (shopType === "shopify")
    return `<script async src="${url}/tracify.js?eid=purchase&amp;csid=${csid}&amp;oid={{ order_number }}&amp;cm={{ customer.email | escape }}&amp;amount={{ total_price | money_without_currency }}&amp;cc={{ currency }}{% for line_item in line_items %}&amp;ITEM{{ forloop.index }}={{ line_item.sku }}&amp;AMT{{ forloop.index }}={{ line_item.line_price | money_without_currency }}&amp;QTY{{ forloop.index }}={{ line_item.quantity }}{% endfor %}"></script>`;
  else if (shopType === "woocommerce") {
    return `<!-- tracify beacon 0.0.1 | (c) tracify.ai | all rights reserved -->

    <?php
    add_action('woocommerce_thankyou', 'js_tracking_thank_you_page', 90, 1);
    function js_tracking_thank_you_page($order_id)
    {
      // Get the WC_Order instance Object
      $order = wc_get_order($order_id);

      // Output
    ?>
      <script type="text/javascript">
      function tracifyDocReady(callback) {
      // in case the document is already rendered
      if (document.readyState !== "loading") callback()
      // modern browsers
      else if (document.addEventListener)
        document.addEventListener("DOMContentLoaded", callback)
      // IE <= 8
      else
        document.attachEvent("onreadystatechange", function () {
          if (document.readyState === "complete") callback()
        })
      }

      function tracifyReportEvent(reportUrl, reqMethod, reqHeaders, reqBody) {
        const xmlHttp = new XMLHttpRequest()
        xmlHttp.open(reqMethod, reportUrl, true)
        for (const [key, value] of Object.entries(reqHeaders)) {
          xmlHttp.setRequestHeader(key, value)
        }
        xmlHttp.send(reqBody)
      }

      tracifyDocReady(() => {
        const csid = "${csid}";

        const order = JSON.parse('<?php echo $order; ?>');
        const params = {
        csid,
        eid: "purchase",
        oid: order.id,
        cm: order.billing.email,
        cc: order.currency,
        amount: order.total,
        };

        const orderedItems = []

        <?php
        // Loop through Order items
        foreach ($order->get_items() as $item) :
          $product = $item->get_product();
        ?>
          orderedItems.push({
          sku: '<?php echo $product->get_sku(); ?>', // product price
          amount: '<?php echo $item->get_total(); ?>',
          quantity: '<?php echo $item->get_quantity(); ?>',
          });
        <?php endforeach; // End of Loop
        ?>

        orderedItems.forEach((item, index) => {
        const idx = index + 1;
        params["ITEM" + idx] = item.sku;
        params["AMT" + idx] = item.amount;
        params["QTY" + idx] = item.quantity;
        })

        const searchParams = new URLSearchParams(params);

        const turl =
        "${url}/tracify.js?" + searchParams.toString();
        const req_headers = { csorigin: window.location.origin };
        tracifyReportEvent(turl, "GET", req_headers, null);
      })
      </script>
    <?php
    }`;
  } else if (shopType === "shopware5") {
    return `Instructions: Please install the latest version of the Tracify Shopware Connect Plugin.\n\nShopware5 Releases on Github: https://github.com/Tracify-ai/shopware5-plugin/releases\nShopware5 Release files on Google Drive: https://drive.google.com/drive/folders/1AhOhLPuyVq5NL_llrzWDIBP6MzvaGtRH?usp=sharing\nInstallation-Tutorial: https://www.tracify.ai/onboarding/cms#Shopware5\nCustomer Shop ID: ${csid}`;
  }
  return "";
};

/**
 * Provides the main tracking code
 * @param {string} shopType Type of shop to create tracking code for
 * @param {string} trackingChannel Tracking channel to use.
 * @param {string} backendUrl URL for corresponding backend.
 * @param {string|undefined} csid ID of the store
 * @return {string} Tracking code for store
 */
const getPostPurchaseCode = (
  shopType: string,
  trackingChannel: string,
  backendUrl: string,
  csid: string | undefined
) => {
  if (csid === undefined) return "";

  let ch;
  if (trackingChannel === "production") {
    ch = "";
  } else {
    ch = "e";
  }

  let url;
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api")
    url = "https://scripting.tracify.ai";
  else if (backendUrl === "https://devhive.tracify.ai/v1/tracify/api")
    url = "https://devscripting.tracify.ai";
  else if (backendUrl === "http://127.0.1.1:8282/v1/tracify/api")
    url = "http://127.0.0.1/scripting";

  if (shopType === "shopify")
    return `<script async src="${url}/pps${ch}.js?csid=${csid}"></script>`;
  else if (shopType === "woocommerce")
    return `<script async src="${url}/ppsw${ch}.js?csid=${csid}"></script>`;
  else if (shopType === "shopware5")
    return `<script async src="${url}/ppss${ch}.js?csid=${csid}"></script>`;
  else if (shopType === "magento2_4")
    return `<script async src="${url}/ppsm${ch}.js?csid=${csid}"></script>`;
  else if (shopType === "custom")
    return `<script async src="${url}/ppsc${ch}.js?csid=${csid}"></script>`;

  return "";
};

const getWebsiteURL = (
  storeURL: string,
  useRedirectGateway: boolean,
  backendUrl: string
) => {
  let url;
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api")
    url = "https://redirect.tracify.ai";
  else if (backendUrl === "https://devhive.tracify.ai/v1/tracify/api")
    url = "https://devredirect.tracify.ai";
  else if (backendUrl === "http://127.0.1.1:8282/v1/tracify/api")
    url = "http://127.0.0.1/redirect";
  return useRedirectGateway ? url : storeURL;
};

function encodeQueryParamsRaw(data: any) {
  const ret: string[] = [];
  for (const [key, value] of Object.entries(data)) {
    ret.push(`${key}=${value}`);
  }
  return ret.join("&");
}

const getURLParams = (
  adsProvider: string,
  landingPageUrl: string,
  csid: string,
  useRedirectGateway: boolean
) => {
  let prefix = "";
  let data = {};
  if (useRedirectGateway)
    data = { ...data, csid, thost: encodeURIComponent(landingPageUrl) };
  if (adsProvider == "meta") {
    data = {
      ...data,
      trc_mcmp_id: "{{campaign.id}}",
      trc_mag_id: "{{adset.id}}",
      trc_mad_id: "{{ad.id}}",
    };
  } else if (adsProvider == "google") {
    data = {
      ...data,
      trc_gcmp_id: "{campaignid}",
      trc_gag_id: "{adgroupid}",
      trc_gad_id: "{creative}",
    };
    prefix = "{lpurl}?";
  } else if (adsProvider == "tiktok") {
    data = {
      ...data,
      trc_ttc_id: "__CAMPAIGN_ID__",
      trc_ttag_id: "__AID__",
      trc_ttad_id: "__CID__",
    };
  } else if (adsProvider == "email") {
    data = {
      ...data,
      utm_source: "campaign-email",
      utm_medium: "email",
      utm_campaign: "YOUR-CAMPAIGN-NAME",
    };
  } else if (adsProvider == "influencer") {
    data = {
      ...data,
      trc_inf_un: "{{username}}",
      trc_inf_cn: "{{channelname}}",
      trc_inf_dc: "{{discountcode}}",
    };
  } else if (adsProvider == "pinterest") {
    data = {
      ...data,
      trc_ptcmp_id: "{campaignid}",
      trc_ptag_id: "{adgroupid}",
      trc_ptad_id: "{adid}",
    };
    prefix = "{unescapedlpurl}?";
  }
  // const searchParams = new URLSearchParams(data);
  // return `${prefix}${searchParams.toString()}`;
  const searchParams = encodeQueryParamsRaw(data);
  return `${prefix}${searchParams}`;
};

const getPrettyRefType = (reftype: string) => {
  if (reftype === "fb") return "Facebook-Account-ID";
  if (reftype === "google") return "Google-Account-ID";
  if (reftype === "klayvio") return "Klayvio-Account-ID";
  if (reftype === "tiktok") return "Tiktok-Account-ID";
  if (reftype === "pinterest") return "Pinterest-Account-ID";
  return reftype;
};

const adAccountHeadCells: HeadCell<
  AdAccount & { storeName: string; csid: string }
>[] = [
  {
    id: "source",
    numeric: false,
    disablePadding: false,
    label: "Reference Type",
  },
  {
    id: "account_id",
    numeric: false,
    disablePadding: false,
    label: "Reference-ID",
  },
  {
    id: "last_updated_at",
    numeric: false,
    disablePadding: false,
    label: "Last Sync",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

function ClientStore() {
  const scope = "kytron::read_events";
  const manageOrganisationsApprovalScope = "manage_organisations";
  const deleteStoresScope = "kytron::delete_customersite";
  const updateStoresScope = "kytron::update_customersite";
  const { csid } = useParams();
  const navigate = useNavigate();
  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const shopconnectorBackendUrl = useSelector(
    (state: RootState) => state.environment.shopconnectorBackendUrl
  );
  const adconnectorBackendUrl = useSelector(
    (state: RootState) => state.environment.adconnectorBackendUrl
  );

  const session = useSelector((state: RootState) => state.user.session);
  const account = useSelector((state: RootState) => state.user.account);
  const stores = useSelector((state: RootState) => state.stores.stores);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const integrations = useSelector(
    (state: RootState) => state.integrations.integrations
  );
  const approvals = useSelector(
    (state: RootState) => state.approvals.approvals
  );
  const siterefs = useSelector((state: RootState) => state.siterefs.siterefs);
  const adAccountsState = useSelector(
    (state: RootState) => state.adAccounts.adAccounts
  );
  const selectedStore = useSelector((state: RootState) =>
    findStore(csid, state.stores.stores)
  );
  const [storeName, setStoreName] = useState(selectedStore?.name ?? "");
  const [storeUrl, setStoreUrl] = useState(selectedStore?.url ?? "");
  const [storeDescription, setStoreDescription] = useState(
    selectedStore?.description ?? ""
  );
  const [isStoreActive, setIsStoreActive] = useState(
    selectedStore?.active ?? false
  );
  const [editDlgOpen, setEditDlgOpen] = useState(false);

  const handleClose = () => {
    setStoreName(selectedStore?.name ?? "");
    setStoreDescription(selectedStore?.description ?? "");
    setStoreUrl(selectedStore?.url ?? "");
    setIsStoreActive(selectedStore?.active ?? false);
    setErrorMessage("");
    setEditDlgOpen(false);
  };
  const handleClickOpen = () => {
    setStoreName(selectedStore?.name ?? "");
    setStoreDescription(selectedStore?.description ?? "");
    setStoreUrl(selectedStore?.url ?? "");
    setIsStoreActive(selectedStore?.active ?? false);
    setEditDlgOpen(true);
  };

  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation | null>();

  const handleUpdate = () => {
    dispatch(listStores({ scope, session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
    dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    dispatch(
      listIntegrations({ session, backendUrl: shopconnectorBackendUrl })
    );
    dispatch(listSiterefs({ session, backendUrl }));
  };
  useEffect(() => {
    if (organisations && stores && selectedStore) {
      const organisation = organisations.find((orga) =>
        orga.customer_sites.find((el) => el.id === selectedStore?.csid)
      );
      setSelectedOrganisation(organisation ?? null);
    }
  }, [organisations, stores, selectedStore]);

  useEffect(() => {
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
    if (accounts.length == 0) {
      dispatch(listAccounts({ session, backendUrl }));
    }
    if (organisations.length == 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
    if (integrations.length == 0) {
      dispatch(
        listIntegrations({ session, backendUrl: shopconnectorBackendUrl })
      );
    }
    if (approvals.length == 0) {
      dispatch(listApprovals({ session, backendUrl }));
    }
    if (siterefs.length == 0) {
      dispatch(listSiterefs({ session, backendUrl }));
    }
    if (adAccountsState?.length == 0) {
      dispatch(listAdAccounts({ session, backendUrl: adconnectorBackendUrl }));
    }
  }, []);

  const manageOrgasApproval = useMemo(
    () =>
      !!account?.scp.any?.find((el) => el === manageOrganisationsApprovalScope),
    [account]
  );

  const updateStoreApproval = useMemo(
    () => !!account?.scp.any?.find((el) => el === updateStoresScope),
    [account]
  );

  const deleteStoreApproval = useMemo(
    () => !!account?.scp.any?.find((el) => el === deleteStoresScope),
    [account]
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [selectedAccountMail, setSelectedAccountMail] = useState("");
  const [addUserDlgOpen, setAddUserDlgOpen] = useState(false);
  const [addOrganisationDlgOpen, setAddOrganisationDlgOpen] = useState(false);
  const [addSiterefDlgOpen, setAddSiterefDlgOpen] = useState(false);
  const [deleteStoreDlgOpen, setDeleteStoreDlgOpen] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [shopSystem, setShopSystem] = useState<
    (typeof shopSystemOptions)[number] | undefined
  >(shopSystemOptions.find((el) => el.value === selectedStore?.shop_system));
  const [refid, setRefid] = useState("");
  const [reftype, setReftype] = useState("google");
  const [searchUser, setSearchUser] = useState("");
  const [tokenModalOpen, setTokenModalOpen] = useState(false);
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [ssTokenUser, setSsTokenUser] = useState("");
  const [ssToken, setSsToken] = useState("");
  const [shopType, setShopType] = useState("shopify");
  const [enableFP, setEnableFP] = useState(true);
  const [trackingChannel, setTrackingChannel] = useState("production");
  const [trackingCode, setTrackingCode] = useState(
    getTrackingCode(
      shopType,
      enableFP,
      trackingChannel,
      backendUrl,
      selectedStore?.csid
    )
  );
  const [checkoutCode, setCheckoutCode] = useState(
    getCheckoutCode(shopType, backendUrl, selectedStore?.csid)
  );
  const [ppsCode, setPPSCode] = useState(
    getPostPurchaseCode(
      shopType,
      trackingChannel,
      backendUrl,
      selectedStore?.csid
    )
  );

  const [adsProvider, setAdsProvider] = useState("meta");
  const [useRedirectGateway, setUseRedirectGateway] = useState(false);
  const [landingPageURL, setLandingPageURL] = useState(
    TUtils.validateURL(selectedStore?.url)
  );
  const [websiteURL, setWebsiteURL] = useState(selectedStore?.url);
  const [URLParameters, setURLParameters] = useState(
    getURLParams(
      adsProvider,
      landingPageURL,
      selectedStore?.csid ?? "",
      useRedirectGateway
    )
  );
  const [domain, setDomain] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [APIKey, setAPIKey] = useState("");
  const [APISecret, setAPISecret] = useState("");
  const [fingerprinting, setFingerprinting] = useState(false);
  const [betaFeatures, setBetaFeatures] = useState(false);
  const [cookie, setCookie] = useState("disabled");
  const [integrationToUpdate, setIntegrationToUpdate] = useState("");
  const [snippet, setSnippet] = useState("second");
  const relevantOrganisation = useMemo(() => {
    if (organisations && stores && selectedStore) {
      const organisation = organisations.find((orga) =>
        orga.customer_sites.find((el) => el.id === selectedStore?.csid)
      );
      return organisation ?? null;
    } else return null;
  }, [organisations, stores, selectedStore]);

  const relevantApprovals = approvals.filter(
    (x) => x.rsid === selectedStore?.csid
  );
  const [approvedUsers, setApprovedtUsers] = useState<Array<Account>>(
    relevantApprovals
      .map((x) => findAccount(x.acid, accounts))
      .filter((el) => el !== undefined) as Account[]
  );
  const relevantUsers = accounts.filter(
    // TODO: can anybody explain what these role magic numbers mean?
    (x) =>
      x.role !== 128 &&
      x.role !== 255 &&
      // inactive users may not be linked to a store
      x.active &&
      findAccount(x.acid, approvedUsers) === undefined
  ) as Array<Account>;

  const relevantOrganisationScriptType =
    relevantOrganisation?.customer_sites[0].detected_script_type;

  useEffect(() => {
    const filteredApprovals = relevantApprovals
      .map((x) => findAccount(x.acid, accounts))
      .filter((el) => el !== undefined) as Account[];
    if (searchUser.length !== 0) {
      setApprovedtUsers(
        filteredApprovals.filter((approval) =>
          approval.email?.toLowerCase().includes(searchUser.toLowerCase())
        )
      );
    } else {
      setApprovedtUsers(filteredApprovals);
    }
  }, [approvals, searchUser, accounts]);

  const relevantSiterefs = useMemo(() => {
    const relevant = siterefs.filter((x) => x.csid === selectedStore?.csid);

    const siterefsWithAccount = relevant.map((el) => {
      const account = adAccountsState.find((x) => x.account_id === el.refid);
      if (account) {
        return { ...el, ...account };
      }
      return el;
    });
    return siterefsWithAccount as (Siteref & AdAccount)[];
  }, [adAccountsState, siterefs, selectedStore]);
  const relevantIntegrations = integrations?.filter(
    (x) => x.csid === selectedStore?.csid
  );

  // const updateTrackingCode = () => {
  //   setTrackingCode(
  //     getTrackingCode(shopType, enableFP, trackingChannel, backendUrl, csid)
  //   );
  //   setCheckoutCode(getCheckoutCode(shopType, backendUrl, selectedStore?.csid));
  // };

  const updateTrackingCodes = (
    shopType: string,
    enableFP: boolean,
    trackingChannel: string
  ) => {
    setTrackingCode(
      getTrackingCode(shopType, enableFP, trackingChannel, backendUrl, csid)
    );
    setCheckoutCode(getCheckoutCode(shopType, backendUrl, selectedStore?.csid));
    setPPSCode(
      getPostPurchaseCode(shopType, trackingChannel, backendUrl, csid)
    );
  };

  const handleShopTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShopType(event.target.value);
    updateTrackingCodes(event.target.value, enableFP, trackingChannel);
  };

  const handleChangeFP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableFP(event.target.checked);
    updateTrackingCodes(shopType, event.target.checked, trackingChannel);
  };

  const handleTrackingChannelChange = (
    _event: React.MouseEvent<HTMLElement>,
    newChannel: string
  ) => {
    setTrackingChannel(newChannel);
    updateTrackingCodes(shopType, enableFP, newChannel);
  };

  const handleAddOrganisation = () => {
    setAddOrganisationDlgOpen(true);
  };

  const handleAddOrganisationClose = () => {
    setAddOrganisationDlgOpen(false);
  };

  const handleAddUser = () => {
    setAddUserDlgOpen(true);
  };

  const handleAddUserClose = () => {
    setAddUserDlgOpen(false);
  };

  const handleRemoveStoreFromOrganisationAccounts = (accountIds: string[]) => {
    const accountApprovals = approvals
      .filter((el) => {
        return (
          el.scope === "kytron::read_events" &&
          el.rsid === selectedStore?.csid &&
          accountIds.includes(el.acid)
        );
      })
      .map((el) => el.asi);
    if (accountApprovals.length > 0) {
      dispatch(
        deleteApproval({
          asis: accountApprovals,
          session,
          backendUrl,
        })
      );
    }
  };
  const handleAddStoreToOrganisationAccounts = (accountIds: string[]) => {
    if (accountIds.length > 0 && selectedStore) {
      batch(() => {
        accountIds.map((el) => {
          dispatch(
            createApproval({
              rsid: selectedStore.csid,
              scope: "kytron::read_events",
              acid: el,
              rstype: 0,
              session,
              backendUrl,
            })
          );
        });
      });
    }
  };

  const handleAddOrganisationToStore = () => {
    if (!selectedOrganisation) return;
    if (relevantOrganisation?.id === selectedOrganisation?.id) {
      return;
    }
    if (relevantOrganisation && selectedStore) {
      // we changed the organisation for this store
      const indexInOrga = relevantOrganisation.customer_sites.findIndex(
        (el) => el.id === selectedStore.csid
      );
      const removedStores =
        indexInOrga !== -1
          ? [
              ...relevantOrganisation.customer_sites.slice(0, indexInOrga),
              ...relevantOrganisation.customer_sites.slice(indexInOrga + 1),
            ]
          : relevantOrganisation.customer_sites;
      dispatch(
        updateOrganisation({
          id: relevantOrganisation.id,
          name: relevantOrganisation?.name,
          client_number: relevantOrganisation?.client_number,
          feature_flags: relevantOrganisation?.feature_flags,
          customer_sites: removedStores,
          accounts:
            relevantOrganisation.accounts?.map((el) => ({ id: el.id })) ?? [],
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then(() => {
          setAddOrganisationDlgOpen(false);
          handleRemoveStoreFromOrganisationAccounts(
            relevantOrganisation.accounts.map((el) => el.id)
          );

          // this is very bad, but since these batch dispatch actions in redux are not async
          // we can't await them which will lead to race condition so we give them a 1s headstart
          // when refactoring theresa, please change
          setTimeout(() => {
            handleUpdate();
          }, 1000);
          setSuccessMessage(
            `Store removed from organisation '${selectedOrganisation?.name}'`
          );
        })
        .catch((err: APIError) => {
          setErrorMessage(
            `Unable to remove store to organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
    if (selectedOrganisation && selectedStore) {
      // we changed the organisation for this store
      dispatch(
        updateOrganisation({
          id: selectedOrganisation.id,
          name: selectedOrganisation?.name,
          client_number: selectedOrganisation?.client_number,
          feature_flags: selectedOrganisation?.feature_flags,
          customer_sites: [
            ...(selectedOrganisation?.customer_sites?.map((el) => ({
              id: el.id,
            })) ?? []),
            { id: selectedStore?.csid },
          ],
          accounts:
            selectedOrganisation.accounts?.map((el) => ({ id: el.id })) ?? [],
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then((createResponse: OrganisationCreateResponse) => {
          setAddOrganisationDlgOpen(false);
          handleAddStoreToOrganisationAccounts(
            selectedOrganisation.accounts.map((el) => el.id)
          );

          // this is very bad, but since these batch dispatch actions in redux are not async
          // we can't await them which will lead to race condition so we give them a 1s headstart
          setTimeout(() => {
            handleUpdate();
          }, 1000);

          setSuccessMessage(
            `Store added '${selectedOrganisation?.name}' with id '${createResponse.id}'`
          );
        })
        .catch((err: APIError) => {
          setErrorMessage(
            `Unable to remove store from organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  const handleApproveAccount = () => {
    const selectedAccounts = accounts.filter(
      (account) => account.email === selectedAccountMail
    );
    if (selectedAccounts.length == 0 || selectedStore == undefined) {
      setErrorMessage(
        `No account or store has been selected! Unable to approve account!`
      );
      setAddUserDlgOpen(false);
      return;
    }
    const selectedAccount = selectedAccounts[0];
    dispatch(
      createApproval({
        acid: selectedAccount.acid,
        scope: "kytron::read_events",
        rsid: selectedStore?.csid,
        rstype: 0,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: ApprovalCreateResponse) => {
        setAddUserDlgOpen(false);
        setSuccessMessage(
          `Account with email '${selectedAccountMail}' was approved to read from store '${selectedStore?.name}' (${createResponse.asi})`
        );
        dispatch(listApprovals({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to approve account with email '${selectedAccountMail}': ${err.error}`
        );
      });
  };

  const handleAddSiteref = () => {
    setAddSiterefDlgOpen(true);
  };

  const handleAddSiterefClose = () => {
    setAddSiterefDlgOpen(false);
  };

  const onRefidInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setRefid(event.target.value);
  };

  const handleReftypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReftype(event.target.value);
  };

  const updateProviderInfo = (
    adsProvider: string,
    landingPageURL: string,
    useRedirectGateway: boolean
  ) => {
    setWebsiteURL(
      getWebsiteURL(landingPageURL, useRedirectGateway, backendUrl)
    );
    setURLParameters(
      getURLParams(
        adsProvider,
        landingPageURL,
        selectedStore?.csid ?? "",
        useRedirectGateway
      )
    );
  };

  const onLandingPageURLChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    const url = TUtils.validateURL(event.target.value);
    setLandingPageURL(url);
    updateProviderInfo(adsProvider, url, useRedirectGateway);
  };

  const onUseRedirectGatewayChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseRedirectGateway(event.target.checked);
    updateProviderInfo(adsProvider, landingPageURL, event.target.checked);
  };

  const onAdsProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdsProvider(event.target.value);
    updateProviderInfo(event.target.value, landingPageURL, useRedirectGateway);
  };

  const onSearchInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchUser(event.target.value);
  };

  const revokeApproval = (acid: string) => {
    // console.log(`Revoking approvals for user ${acid}`);
    const userApprovals = relevantApprovals
      .filter((approval) => approval.acid === acid)
      .map((approval) => approval.asi);
    // approvals = approvals.filter((approval) => !userApprovals.includes(approval.asi));
    // console.log(`Revoking approvals ${JSON.stringify(userApprovals)}`);
    dispatch(
      deleteApproval({
        asis: userApprovals,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        setSuccessMessage(
          `Approvals for user with id '${acid}' have been revoked for store '${selectedStore?.name}'`
        );
        dispatch(listApprovals({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to revoke approval for user with id '${acid}': ${err.error}`
        );
      });
  };

  const unlinkAccounts = (csrids: Array<string>) => {
    dispatch(
      deleteSiteref({
        csrids,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        setSuccessMessage(
          `Reference(s) '${csrids}' has/have been unlinked from '${selectedStore?.name}'`
        );
        dispatch(listSiterefs({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to unlink reference(s) '${csrids}': ${err.error}`
        );
      });
  };

  const handleCreateSiteref = () => {
    if (selectedStore == undefined) {
      setErrorMessage(`No store has been selected! Unable to link account!`);
      setAddSiterefDlgOpen(false);
      return;
    }
    dispatch(
      createSiteref({
        csid: selectedStore.csid,
        refid: refid,
        reftype: reftype,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: SiterefCreateResponse) => {
        setAddSiterefDlgOpen(false);
        setSuccessMessage(
          `Account with ID '${refid}' was linked with store '${selectedStore?.name}' (${createResponse.csrid})`
        );
        dispatch(listSiterefs({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to link account with id '${refid}': ${err.error}`
        );
      });
  };

  const handleDeleteStore = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setDeleteStoreDlgOpen(true);
  };

  const handleDeleteStoreClose = () => {
    setDeleteStoreDlgOpen(false);
  };

  const handleDeleteStoreAction = () => {
    if (!selectedStore) return;

    dispatch(
      deleteStore({
        csid: selectedStore.csid,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        handleUpdate();
        navigate(`/stores`);
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to delete store '${selectedStore.name}': ${err.error}`
        );
      });
  };

  const handleCreateServerToken = () => {
    if (!selectedStore) return;
    if (!ssTokenUser) return;

    dispatch(
      createStoreServerToken({
        csid: selectedStore?.csid,
        id: ssTokenUser,
        session,
        backendUrl: chiefBackendUrl,
      })
    )
      .unwrap()
      .then((createResponse: StoreServerTokenCreateResponse) => {
        setSsToken(createResponse.token);
        setSuccessMessage(
          `Server side token for store '${selectedStore?.description}' was successfully created: ${createResponse.token}`
        );
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to create server side token for store '${selectedStore.description}': ${err.error}`
        );
      });
  };

  const handleOpenTokenModal = () => {
    setTokenModalOpen(true);
    setSsToken("");
    setSsTokenUser("");
  };

  const handleCloseTokenModal = () => {
    setTokenModalOpen(false);
    setSsToken("");
    setSsTokenUser("");
  };

  const handleOpenIntegrationModal = () => {
    setIntegrationModalOpen(true);
    //  setIntegration('');
  };

  useEffect(() => {
    const system = shopSystemOptions.find(
      (el) => el.value === selectedStore?.shop_system
    );
    if (
      (!shopSystem?.value ||
        shopSystem?.value !== selectedStore?.shop_system) &&
      system
    )
      setShopSystem(system);
  }, [selectedStore?.shop_system]);

  const onIntegrationUpdate = async (id: string) => {
    if (!selectedStore) return;
    if (!id) return;
    setErrorMessage("");
    setSuccessMessage("");
    return dispatch(
      getShopifyIntegration({
        id,
        session,
        backendUrl: shopconnectorBackendUrl,
      })
    )
      .unwrap()
      .then((integration: ShopifyIntegration) => {
        if (!integration) return;
        setIntegrationToUpdate(id);
        setDomain(integration.domain);
        setAPIKey(integration.api_key);
        setAccessToken(integration.access_token);
        setAPISecret(integration.api_secret);
        setFingerprinting(integration.fingerprinting_active);
        setBetaFeatures(integration.beta_active);
        setCookie(
          integration.cookie_active_without_consent
            ? "cookie_active_without_consent"
            : integration.cookie_active_with_consent
            ? "cookie_active_with_consent"
            : "disabled"
        );
        setSnippet(integration.snippet_content ?? "");
        handleOpenIntegrationModal();
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to get shopify integration for integration ID '${id}': ${err.error}`
        );
      });
  };

  const handleCloseIntegrationModal = () => {
    setIntegrationModalOpen(false);
    setIntegrationToUpdate("");
    setErrorMessage("");
    setSuccessMessage("");
    setDomain("");
    setAPIKey("");
    setAccessToken("");
    setAPISecret("");
    setFingerprinting(false);
    setBetaFeatures(false);
    setShowPassword(false);
    setCookie("disabled");
    setSnippet("");
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onDomainInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setDomain(event.target.value);
  };

  const onAccessTokenInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setAccessToken(event.target.value);
  };

  const onAPIKeyInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setAPIKey(event.target.value);
  };

  const onAPISecretInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setAPISecret(event.target.value);
  };

  const onFingerprintingInputChange = (_event: any, checked: boolean) => {
    setFingerprinting(checked);
  };
  const onBetaInputChange = (_event: any, checked: boolean) => {
    setBetaFeatures(checked);
  };
  const onCookieInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCookie((event.target as HTMLInputElement).value);
  };
  const handleCreateShopifyIntegration = () => {
    if (!selectedStore) return;
    if (!accessToken || !APIKey || !APISecret || !domain) {
      return setErrorMessage("Please fill out all fields");
    }
    setErrorMessage("");
    setSuccessMessage("");
    dispatch(
      createShopifyIntegration({
        csid: selectedStore?.csid,
        access_token: accessToken,
        api_key: APIKey,
        api_secret: APISecret,
        beta_active: betaFeatures,
        cookie_active_without_consent:
          cookie === "cookie_active_without_consent",
        cookie_active_with_consent: cookie === "cookie_active_with_consent",
        fingerprinting_active: fingerprinting,
        domain: domain,
        session,
        backendUrl: shopconnectorBackendUrl,
      })
    )
      .unwrap()
      .then((integration) => {
        setSuccessMessage(
          `Shopify integration for domain '${domain}' was successfully created`
        );
        handleUpdate();
        setIntegrationToUpdate(integration.id);
        setSnippet(integration.snippet_content ?? "");
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to create integration for store '${selectedStore.description}': ${err.error}`
        );
      });
  };
  const handleUpdateShopifyIntegration = () => {
    if (!selectedStore) return;
    if (!integrationToUpdate) return;
    if (!accessToken || !APIKey || !APISecret || !domain) {
      return setErrorMessage("Please fill out all fields");
    }
    setErrorMessage("");
    setSuccessMessage("");
    dispatch(
      updateShopifyIntegration({
        id: integrationToUpdate,
        csid: selectedStore?.csid,
        access_token: accessToken,
        api_key: APIKey,
        api_secret: APISecret,
        beta_active: betaFeatures,
        cookie_active_without_consent:
          cookie === "cookie_active_without_consent",
        cookie_active_with_consent: cookie === "cookie_active_with_consent",
        fingerprinting_active: fingerprinting,
        domain: domain,
        session,
        backendUrl: shopconnectorBackendUrl,
      })
    )
      .unwrap()
      .then((integration) => {
        setSuccessMessage(
          `Shopify integration for domain '${domain}' was successfully updated`
        );
        setSnippet(integration.snippet_content ?? "");
        handleUpdate();
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to update integration for store '${selectedStore.description}': ${err.error}`
        );
      });
  };
  const handleDeleteShopifyIntegration = (id: string) => {
    if (!selectedStore) return;
    setErrorMessage("");
    setSuccessMessage("");
    dispatch(
      deleteShopifyIntegration({
        id,
        session,
        backendUrl: shopconnectorBackendUrl,
      })
    )
      .unwrap()
      .then(() => {
        setSuccessMessage(
          `Shopify integration for store '${selectedStore?.description}' was successfully deleted`
        );
        handleUpdate();
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to delete Shopify integration for store '${selectedStore.description}': ${err.error}`
        );
      });
  };

  const handleUpdateStore = () => {
    // until now, this function is only used to update the shopSystem
    if (!selectedStore) return;
    if (!shopSystem?.value) {
      return setErrorMessage(
        "No Shop System selected. Please fill out all fields before updating."
      );
    }
    setErrorMessage("");
    setSuccessMessage("");
    dispatch(
      updateStore({
        csid: selectedStore.csid,
        name: storeName ?? selectedStore.name,
        url: storeUrl ?? selectedStore.url,
        shop_system: shopSystem?.value ?? "",
        description: storeDescription ?? selectedStore.description,
        pg_idx: selectedStore?.pg_idx,
        redis_idx: selectedStore?.redis_idx,
        active: isStoreActive,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        setSuccessMessage(
          `Update for store '${selectedStore.description}' was successful`
        );
        handleUpdate();
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to update shop system for store '${selectedStore.description}': ${err.error}`
        );
      })
      .finally(() => {
        setEditDlgOpen(false);
      });
  };

  return (
    <div>
      <div className="centered">
        <Box sx={{ width: "100%" }}>
          <Collapse in={successMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="success"
              onClose={() => {
                setSuccessMessage("");
              }}
            >
              {successMessage}
            </Alert>
          </Collapse>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
              style={headerStyle}
            >
              {selectedStore?.name} - {selectedStore?.description} - cluster{" "}
              {/* We need to avoid checking selectedStore.pg_idx && because if its 0 it will be treaten as faulty */}
              {selectedStore?.pg_idx !== undefined
                ? selectedStore.pg_idx + 1
                : ""}
              {selectedStore?.active ? (
                <span
                  style={{
                    background: "green",
                    color: "white",
                    padding: "4px",
                    borderRadius: 4,
                    fontSize: 16,
                    fontWeight: 500,
                    marginLeft: 6,
                  }}
                >
                  Active
                </span>
              ) : (
                <span
                  style={{
                    background: "#555",
                    color: "ddd",
                    padding: "4px",
                    borderRadius: 4,
                    fontSize: 16,
                    fontWeight: 500,
                    marginLeft: 6,
                  }}
                >
                  Inactive
                </span>
              )}
              <Button
                aria-label="create"
                variant="contained"
                onClick={handleClickOpen}
                style={{ marginLeft: "1rem" }}
              >
                Edit Store
              </Button>
            </Typography>

            <Dialog open={editDlgOpen} onClose={handleClose}>
              <DialogTitle>Edit Store</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please provider the corresponding store info here:
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  label="Company name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                />
                <TextField
                  required
                  margin="dense"
                  id="url"
                  label="URL"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={storeUrl}
                  onChange={(e) => setStoreUrl(e.target.value)}
                />
                <TextField
                  required
                  margin="dense"
                  id="description"
                  label="Store name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={storeDescription}
                  onChange={(e) => setStoreDescription(e.target.value)}
                />
                <Autocomplete
                  disablePortal
                  id="shop-system-modal"
                  options={shopSystemOptions}
                  sx={{ width: "100%", paddingLeft: 0 }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, selection) => {
                    return option.value === selection?.value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Shop System"
                      variant="standard"
                      defaultValue={shopSystem?.label}
                    />
                  )}
                  value={shopSystem}
                  onChange={(_event: any, newValue: any) =>
                    setShopSystem(newValue)
                  }
                />
                <FormGroup
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    marginTop: "1rem",
                  }}
                >
                  <FormLabel id="active-label">Store Status</FormLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isStoreActive}
                        onChange={(_e, checked) => setIsStoreActive(checked)}
                        name="active"
                      />
                    }
                    label="Active"
                    sx={{ width: "100px", marginLeft: 2, marginRight: 2 }}
                  />
                </FormGroup>
                {errorMessage ? (
                  <Alert
                    variant="outlined"
                    severity="error"
                    onClose={() => {
                      setErrorMessage("");
                    }}
                  >
                    {errorMessage}
                  </Alert>
                ) : null}
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={handleUpdateStore}
                  disabled={storeName.length < 4}
                >
                  Update
                </Button>
              </DialogActions>
            </Dialog>

            <div className="hboxa">
              <TextField
                disabled
                id="outlined-disabled"
                label="URL"
                value={selectedStore?.url}
                style={defaultStyle}
                sx={{ width: "90%" }}
              />
              <IconButton
                aria-label="copy"
                onClick={() =>
                  navigator.clipboard.writeText(selectedStore?.url ?? "")
                }
                sx={{
                  marginTop: "0.5em",
                  marginLeft: "0.2em",
                  width: "2em",
                  height: "2em",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
            <div className="hboxa">
              <TextField
                disabled
                id="outlined-disabled"
                label="Store-ID"
                value={selectedStore?.csid}
                style={defaultStyle}
                sx={{ width: "36%" }}
              />
              <IconButton
                aria-label="copy"
                onClick={() =>
                  navigator.clipboard.writeText(selectedStore?.csid ?? "")
                }
                sx={{
                  marginTop: "0.5em",
                  marginLeft: "0.2em",
                  width: "2em",
                  height: "2em",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
            {relevantOrganisationScriptType && (
              <div className="hboxa">
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Detected script type"
                  value={relevantOrganisationScriptType}
                  style={defaultStyle}
                  sx={{ width: "36%" }}
                />
                <IconButton
                  aria-label="copy"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      relevantOrganisationScriptType ?? ""
                    )
                  }
                  sx={{
                    marginTop: "0.5em",
                    marginLeft: "0.2em",
                    width: "2em",
                    height: "2em",
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </div>
            )}
            {updateStoreApproval ? (
              <div
                className="hboxa"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Autocomplete
                  disablePortal
                  id="shop-system"
                  options={shopSystemOptions}
                  sx={{ width: "36%" }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, selection) => {
                    return option.value === selection?.value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Shop System"
                      variant="outlined"
                      defaultValue={shopSystem?.label}
                    />
                  )}
                  style={defaultStyle}
                  value={shopSystem}
                  onChange={(_event: any, newValue: any) =>
                    setShopSystem(newValue)
                  }
                />
                {shopSystem?.value &&
                shopSystem?.value !== selectedStore?.shop_system ? (
                  <Button onClick={handleUpdateStore} variant="contained">
                    Save
                  </Button>
                ) : null}
              </div>
            ) : null}
            {manageOrgasApproval ? (
              <div className="hboxa">
                <Button
                  variant="contained"
                  onClick={() => handleOpenTokenModal()}
                  style={{ marginLeft: 24 }}
                >
                  Create server-side token
                </Button>
                <Dialog open={tokenModalOpen} onClose={handleCloseTokenModal}>
                  <DialogTitle>
                    Create server-side token for {selectedStore?.description}
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <TextField
                      id="outlined-disabled"
                      label="User ID"
                      value={ssTokenUser}
                      onChange={(e) => setSsTokenUser(e.target.value.trim())}
                      sx={{ width: "100%" }}
                    />
                    <p>
                      After creating the token it will be displayed below.
                      Please make sure to immediately copy or write it down
                      somewhere, because you won't be able to see it after
                      closing this modal
                    </p>
                    <p style={{ overflowWrap: "break-word", width: "100%" }}>
                      <b>Created server-side token: </b>
                      <br />
                      <span>{ssToken}</span>
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseTokenModal}>Cancel</Button>
                    <Button onClick={handleCreateServerToken}>
                      Create Token
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : null}
            {/* <div className="hboxa">
              <Autocomplete
                disablePortal
                id="combo-stores"
                options={accounts}
                sx={{ width: 300 }}
                getOptionLabel={(option) => option.email}
                renderInput={(params) => <TextField {...params} label="User" />}
                style={defaultStyle}
                // value={selectedStore}
                // onChange={(_event: any, newValue: Store | null) => setSelectedStore(newValue)}
                inputValue={selectedAccountMail}
                onInputChange={(_event, newInputValue) =>
                  setSelectedAccountMail(newInputValue)
                }
              />
              <Button
                aria-label="add"
                startIcon={<AddCircleOutlineIcon />}
                style={defaultStyle}
                disabled={selectedAccountMail.length < 1}
              >
                Add User
              </Button>
            </div> */}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, paddingTop: "1em" }}
              style={headerStyle}
            >
              Tracking Code
            </Typography>
            <div className="boxb">
              <FormControl>
                <FormLabel id="rb-shoptype-label">Type of shop:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="rb-shoptype-label"
                  name="rb-shoptype"
                  value={shopType}
                  onChange={handleShopTypeChange}
                >
                  <FormControlLabel
                    value="shopify"
                    control={<Radio />}
                    label="Shopify"
                  />
                  <FormControlLabel
                    value="woocommerce"
                    control={<Radio />}
                    label="WooCommerce"
                  />
                  <FormControlLabel
                    value="shopware5"
                    control={<Radio />}
                    label="Shopware 5"
                  />
                  <FormControlLabel
                    value="shopware6"
                    control={<Radio />}
                    label="Shopware 6"
                  />
                  <FormControlLabel
                    value="magento2_4"
                    control={<Radio />}
                    label="Magento v2.4"
                  />
                  <FormControlLabel
                    value="digistore24"
                    control={<Radio />}
                    label="DigiStore24"
                  />
                  <FormControlLabel
                    value="elopage"
                    control={<Radio />}
                    label="Elopage"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="hboxb">
              <FormGroup>
                <FormLabel id="fingerprinting-label">Options:</FormLabel>
                <FormControlLabel
                  control={<Switch defaultChecked onChange={handleChangeFP} />}
                  label="Enable Fingerprinting"
                  value={enableFP}
                />
              </FormGroup>
              <ToggleButtonGroup
                color="primary"
                value={trackingChannel}
                exclusive
                onChange={handleTrackingChannelChange}
                sx={{ height: "1em", paddingTop: "2em" }}
              >
                <ToggleButton value="production">Production</ToggleButton>
                <ToggleButton value="beta">Beta</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="boxb">
              <TextField
                disabled
                id="outlined-multiline-flexible"
                label="Main tracking code"
                multiline
                rows={3}
                value={trackingCode}
                sx={{ width: "90%", marginTop: "1em" }}
                onClick={() => navigator.clipboard.writeText(trackingCode)}
              />
              <IconButton
                aria-label="copy"
                onClick={() => navigator.clipboard.writeText(trackingCode)}
                sx={{
                  marginTop: "1.4em",
                  marginLeft: "0.2em",
                  width: "2em",
                  height: "2em",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
            <div className="boxb">
              <TextField
                disabled
                id="outlined-multiline-flexible"
                label="Checkout code (legacy)"
                multiline
                rows={6}
                value={checkoutCode}
                sx={{ width: "90%", marginTop: "1em" }}
                onClick={() => navigator.clipboard.writeText(checkoutCode)}
              />
              <IconButton
                aria-label="copy"
                onClick={() => navigator.clipboard.writeText(checkoutCode)}
                sx={{
                  marginTop: "3.4em",
                  marginLeft: "0.2em",
                  width: "2em",
                  height: "2em",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
            <div className="boxb">
              <TextField
                disabled
                id="outlined-multiline-flexible"
                label="Postpurchase tracking code (legacy)"
                multiline
                rows={6}
                value={ppsCode}
                sx={{ width: "90%", marginTop: "1em" }}
                onClick={() => navigator.clipboard.writeText(ppsCode)}
              />
              <IconButton
                aria-label="copy"
                onClick={() => navigator.clipboard.writeText(ppsCode)}
                sx={{
                  marginTop: "3.4em",
                  marginLeft: "0.2em",
                  width: "2em",
                  height: "2em",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, paddingTop: "1em" }}
                style={headerStyle}
              >
                Organisation
              </Typography>
            </div>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Organisation-ID</TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relevantOrganisation && (
                      <TableRow
                        key={relevantOrganisation?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {relevantOrganisation?.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {relevantOrganisation?.name}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {manageOrgasApproval ? (
                <Button
                  aria-label="add"
                  onClick={handleAddOrganisation}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{ paddingTop: "1em" }}
                >
                  Manage Organisation
                </Button>
              ) : null}
              <Dialog
                open={addOrganisationDlgOpen}
                onClose={handleAddOrganisationClose}
              >
                <DialogTitle>Add organisation to store</DialogTitle>
                <DialogContent sx={{ minHeight: "50vh", minWidth: "500px" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-stores"
                    options={organisations}
                    sx={{ width: "100%", paddingRight: "2em" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Organisation" />
                    )}
                    style={defaultStyle}
                    value={selectedOrganisation}
                    onChange={(_event: any, newValue: Organisation | null) =>
                      setSelectedOrganisation(newValue)
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddOrganisationClose}>Cancel</Button>
                  <Button onClick={handleAddOrganisationToStore}>Update</Button>
                </DialogActions>
              </Dialog>
            </div>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, paddingTop: "1em" }}
              style={headerStyle}
            >
              Approved Users
            </Typography>
            <div className="hbox" style={searchBoxStyle}>
              <SearchIcon />
              <Input
                placeholder="Search"
                className="flex flex-1 mx-8"
                disableUnderline
                fullWidth
                value={searchUser}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={onSearchInputChange}
                style={searchInputStyle}
              />
            </div>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Account-ID</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">Op</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {approvedUsers &&
                      approvedUsers.map((approvedUser) => (
                        <TableRow
                          key={approvedUser?.acid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {approvedUser?.acid}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {approvedUser?.email}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip
                              title={`Revoke access for user ${approvedUser?.email}`}
                            >
                              <PersonRemoveIcon
                                onClick={() =>
                                  approvedUser?.acid &&
                                  revokeApproval(approvedUser?.acid)
                                }
                                cursor="pointer"
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                aria-label="add"
                onClick={handleAddUser}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ paddingTop: "1em" }}
              >
                Add User
              </Button>
              <Dialog open={addUserDlgOpen} onClose={handleAddUserClose}>
                <DialogTitle>Approve user account</DialogTitle>
                <DialogContent sx={{ minHeight: "50vh", minWidth: "500px" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-stores"
                    options={relevantUsers}
                    sx={{ width: "100%", paddingRight: "2em" }}
                    getOptionLabel={(option) => option.email}
                    renderInput={(params) => (
                      <TextField {...params} label="User" />
                    )}
                    style={defaultStyle}
                    // value={selectedStore}
                    // onChange={(_event: any, newValue: Store | null) => setSelectedStore(newValue)}
                    inputValue={selectedAccountMail}
                    onInputChange={(_event, newInputValue) =>
                      setSelectedAccountMail(newInputValue)
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddUserClose}>Cancel</Button>
                  <Button
                    onClick={handleApproveAccount}
                    disabled={selectedAccountMail.length == 0}
                  >
                    Approve
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, paddingTop: "1em" }}
              style={headerStyle}
            >
              Linked Accounts
            </Typography>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Reference-ID</TableCell>
                      <TableCell>Reference Type</TableCell>
                      <TableCell>Last Sync</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">Op</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relevantSiterefs &&
                      relevantSiterefs.map((sref) => (
                        <TableRow
                          key={sref.refid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {sref.refid}
                          </TableCell>
                          <TableCell>
                            {getPrettyRefType(sref.reftype)}
                          </TableCell>
                          <TableCell>
                            {sref.last_updated_at
                              ? DateTime.fromISO(sref.last_updated_at).toFormat(
                                  "yyyy-MM-dd HH:mm:ss"
                                )
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {!sref.last_updated_at ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ErrorOutlineSharp color="error" />{" "}
                                <span style={{ marginLeft: 4 }}>
                                  No adconnector data found
                                </span>
                              </div>
                            ) : DateTime.fromISO(sref.last_updated_at).diffNow(
                                "days"
                              ).days *
                                -1 >
                              1 ? (
                              <ErrorOutlineSharp color="error" />
                            ) : (
                              <CheckCircleOutline color="success" />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title={`Unlink account ${sref.refid}`}>
                              <PersonRemoveIcon
                                onClick={() => unlinkAccounts([sref?.csrid])}
                                cursor="pointer"
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                aria-label="add"
                onClick={handleAddSiteref}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ paddingTop: "1em" }}
              >
                Link Account
              </Button>
              <Dialog open={addSiterefDlgOpen} onClose={handleAddSiterefClose}>
                <DialogTitle>Link account</DialogTitle>
                <DialogContentText>
                  Please provide info for account to be linked here:
                </DialogContentText>
                <DialogContent sx={{ minHeight: "20vh", minWidth: "500px" }}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="refid"
                    label="Account-ID"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={refid}
                    onChange={onRefidInputChange}
                  />
                  <FormControl>
                    <FormLabel id="rb-reftype-label">Type of shop:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="rb-reftype-label"
                      name="rb-reftype"
                      value={reftype}
                      onChange={handleReftypeChange}
                    >
                      <FormControlLabel
                        value="google"
                        control={<Radio />}
                        label="Google-Account-ID"
                      />
                      <FormControlLabel
                        value="klayvio"
                        control={<Radio />}
                        label="Klayvio-Account-ID"
                      />
                      <FormControlLabel
                        value="tiktok"
                        control={<Radio />}
                        label="Tiktok-Account-ID"
                        disabled
                      />
                      <FormControlLabel
                        value="pinterest"
                        control={<Radio />}
                        label="Pinterest-Account-ID"
                      />
                      <FormControlLabel
                        value="fb"
                        control={<Radio />}
                        label="Facebook-Account-ID"
                        disabled
                      />
                    </RadioGroup>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddSiterefClose}>Cancel</Button>
                  <Button
                    onClick={handleCreateSiteref}
                    disabled={
                      refid.length < 4 ||
                      relevantSiterefs?.filter((x) => x.refid == refid).length >
                        0
                    }
                  >
                    Link
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, paddingTop: "1em" }}
                style={headerStyle}
              >
                Integrations
              </Typography>
            </div>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Integration-ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Ops</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relevantIntegrations &&
                      relevantIntegrations.map((integration) => (
                        <TableRow
                          key={integration.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {integration.id}
                          </TableCell>
                          <TableCell>{integration.name}</TableCell>
                          <TableCell>{integration.type}</TableCell>
                          <TableCell align="right">
                            <Tooltip
                              title={`Edit integration for ${integration.name}`}
                            >
                              <EditIcon
                                onClick={() =>
                                  onIntegrationUpdate(integration.id)
                                }
                                style={{ marginRight: 16 }}
                                cursor="pointer"
                              />
                            </Tooltip>
                            <Tooltip
                              title={`Remove integration for ${integration.name}`}
                            >
                              <PersonRemoveIcon
                                onClick={() =>
                                  handleDeleteShopifyIntegration(integration.id)
                                }
                                cursor="pointer"
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                aria-label="add"
                onClick={handleOpenIntegrationModal}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ paddingTop: "1em" }}
              >
                Create Integration
              </Button>
              <Dialog
                open={integrationModalOpen}
                onClose={handleCloseIntegrationModal}
              >
                <DialogTitle>Create an integration</DialogTitle>
                <DialogContent sx={{ minHeight: "50vh", minWidth: "500px" }}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="domain"
                    label="Domain"
                    placeholder="example.myshopify.com"
                    type="text"
                    fullWidth
                    error={Boolean(errorMessage && !domain)}
                    variant="outlined"
                    value={domain}
                    onChange={onDomainInputChange}
                  />
                  <FormControl
                    sx={{ width: "100%", my: 0.5 }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="access_token">Access Token</InputLabel>
                    <OutlinedInput
                      id="access_token"
                      type={showPassword ? "text" : "password"}
                      value={accessToken}
                      onChange={onAccessTokenInputChange}
                      error={Boolean(errorMessage && !accessToken)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Access Token"
                    />
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", my: 0.5 }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="api_token">API Key</InputLabel>
                    <OutlinedInput
                      id="api_token"
                      type={showPassword ? "text" : "password"}
                      value={APIKey}
                      onChange={onAPIKeyInputChange}
                      error={Boolean(errorMessage && !APIKey)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="API Key"
                    />
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", my: 0.5 }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="api_secret">API Secret</InputLabel>
                    <OutlinedInput
                      id="api_secret"
                      type={showPassword ? "text" : "password"}
                      value={APISecret}
                      error={Boolean(errorMessage && !APISecret)}
                      onChange={onAPISecretInputChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="API Secret"
                    />
                  </FormControl>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fingerprinting}
                          onChange={onFingerprintingInputChange}
                        />
                      }
                      label="Enable fingerprinting"
                    />
                  </div>

                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={betaFeatures}
                          onChange={onBetaInputChange}
                        />
                      }
                      label="Enable latest beta features"
                    />
                  </div>
                  <div>
                    <FormLabel id="cookie-group-label">Cookies</FormLabel>
                    <RadioGroup
                      aria-labelledby="cookie-group-label"
                      defaultValue="disabled"
                      value={cookie}
                      onChange={onCookieInputChange}
                      name="cookie-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="disabled"
                        control={<Radio />}
                        label="Disable Cookie Tracking"
                        sx={{
                          "&.MuiRadio-root": {
                            padding: 4,
                          },
                        }}
                      />
                      <FormControlLabel
                        value="cookie_active_with_consent"
                        control={<Radio />}
                        label="Enable cookie tracking (EU shop)"
                        sx={{
                          "&.MuiRadio-root": {
                            padding: 4,
                          },
                        }}
                      />
                      <FormControlLabel
                        value="cookie_active_without_consent"
                        control={<Radio />}
                        label="Enable cookie tracking (Non EU shop)"
                        sx={{
                          "&.MuiRadio-root": {
                            padding: 4,
                          },
                        }}
                      />
                    </RadioGroup>
                    {snippet && cookie === "cookie_active_with_consent" ? (
                      <>
                        <p>Code Snippet</p>
                        <Box
                          component="span"
                          sx={{
                            display: "block",
                            p: 2,
                            bgcolor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#1f1f1f"
                                : "#fff",
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "grey.300"
                                : "grey.800",
                            border: "1px solid",
                            borderColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "grey.800"
                                : "grey.300",
                            borderRadius: 2,
                            fontSize: "0.875rem",
                            fontWeight: "700",
                          }}
                        >
                          {snippet}
                        </Box>
                      </>
                    ) : null}
                  </div>
                  {errorMessage ? (
                    <Alert
                      variant="outlined"
                      severity="error"
                      onClose={() => {
                        setErrorMessage("");
                      }}
                      style={{ marginTop: 20 }}
                    >
                      {errorMessage}
                    </Alert>
                  ) : null}
                  {successMessage ? (
                    <Alert
                      variant="outlined"
                      severity="success"
                      onClose={() => {
                        setSuccessMessage("");
                      }}
                      style={{ marginTop: 20 }}
                    >
                      {successMessage}
                    </Alert>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseIntegrationModal}>Cancel</Button>
                  <Button
                    onClick={
                      integrationToUpdate
                        ? handleUpdateShopifyIntegration
                        : handleCreateShopifyIntegration
                    }
                  >
                    {integrationToUpdate ? "Update" : "Create"}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, paddingTop: "1em" }}
              style={headerStyle}
            >
              Ads URL-Parameter builder
            </Typography>

            <div className="boxb">
              <FormControl>
                <FormLabel id="rb-shoptype-label">Ad-Provider:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="rb-shoptype-label"
                  name="rb-shoptype"
                  value={adsProvider}
                  onChange={onAdsProviderChange}
                >
                  <FormControlLabel
                    value="meta"
                    control={<Radio />}
                    label="Meta"
                  />
                  <FormControlLabel
                    value="google"
                    control={<Radio />}
                    label="Google"
                  />
                  <FormControlLabel
                    value="tiktok"
                    control={<Radio />}
                    label="Tiktok"
                  />
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                  <FormControlLabel
                    value="influencer"
                    control={<Radio />}
                    label="Influencer"
                  />
                  <FormControlLabel
                    value="pinterest"
                    control={<Radio />}
                    label="Pinterest"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="hboxb">
              <FormGroup>
                <FormLabel id="redirect-label">Options:</FormLabel>
                <FormControlLabel
                  control={<Switch onChange={onUseRedirectGatewayChange} />}
                  label="Use Redirect-Gateway"
                  value={useRedirectGateway}
                />
              </FormGroup>
            </div>

            <div className="boxb">
              <TextField
                id="outlined-multiline-flexible"
                label="Landing Page URL"
                multiline
                rows={1}
                sx={{ width: "90%", marginTop: "1em", paddingBottom: "1em" }}
                value={landingPageURL}
                onChange={onLandingPageURLChanged}
              />
            </div>

            <div className="boxb">
              <TextField
                disabled
                id="outlined-multiline-flexible"
                label="Website URL"
                multiline
                rows={1}
                sx={{ width: "90%", marginTop: "1em", paddingBottom: "1em" }}
                value={websiteURL}
                onClick={() => navigator.clipboard.writeText(websiteURL ?? "")}
              />
              <IconButton
                aria-label="copy"
                onClick={() => navigator.clipboard.writeText(websiteURL ?? "")}
                sx={{
                  marginTop: "1.0em",
                  marginLeft: "0.2em",
                  width: "2em",
                  height: "2em",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>

            <div className="boxb">
              <TextField
                disabled
                id="outlined-multiline-flexible"
                label="URL Parameters"
                multiline
                rows={3}
                sx={{ width: "90%", marginTop: "1em", paddingBottom: "3em" }}
                value={URLParameters}
                onClick={() => navigator.clipboard.writeText(URLParameters)}
              />
              <IconButton
                aria-label="copy"
                onClick={() => navigator.clipboard.writeText(URLParameters)}
                sx={{
                  marginTop: "2.0em",
                  marginLeft: "0.2em",
                  width: "2em",
                  height: "2em",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
            {deleteStoreApproval ? (
              <div style={{ paddingBottom: 48 }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, paddingBottom: 1 }}
                  style={headerStyle}
                >
                  Danger Zone
                </Typography>
                <div className="boxb">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteStore}
                  >
                    Delete Store
                  </Button>
                  <Dialog
                    open={deleteStoreDlgOpen}
                    onClose={handleDeleteStoreClose}
                  >
                    <DialogTitle>
                      Delete store {selectedStore?.description ?? ""}
                    </DialogTitle>
                    <DialogContent>
                      <p>
                        Are you sure you want to delete this store:{" "}
                        <b>{selectedStore?.description ?? ""}</b>
                      </p>
                      <p> This action can't be reversed.</p>
                      {errorMessage ? (
                        <Alert
                          variant="outlined"
                          severity="error"
                          onClose={() => {
                            setErrorMessage("");
                          }}
                        >
                          {errorMessage}
                        </Alert>
                      ) : null}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDeleteStoreClose}>Cancel</Button>
                      <Button
                        onClick={handleDeleteStoreAction}
                        color="error"
                        variant="contained"
                      >
                        Delete Store
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            ) : null}
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default ClientStore;
