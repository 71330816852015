import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  APIError,
  Organisation,
  OrganisationListRequest,
  OrganisationCreateRequest,
  OrganisationCreateResponse,
  OrganisationUpdateRequest,
  OrganisationDeleteRequest,
  ChiefAPIError,
} from "../backendTypes";

const initialState = {
  organisations: [] as Array<Organisation>,
  organisationError: "",
};

export const listOrganisations = createAsyncThunk<
  // Return type of the payload creator
  Array<Organisation>,
  // First argument to the payload creator
  OrganisationListRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "organisations/list",
  // Declare the type your function argument here:
  async (requestData: OrganisationListRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.get(
        `${requestData.backendUrl}/v4/organisations/list`,
        {
          params: {
            limit: 100000,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        const errorResponse: ChiefAPIError = response.data;
        return rejectWithValue({
          error: errorResponse.detail[0].msg,
        } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.items as Array<Organisation>;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }

        const errorResponse: ChiefAPIError = err.response.data;
        return rejectWithValue({
          error: errorResponse.detail[0].msg,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const createOrganisation = createAsyncThunk<
  // Return type of the payload creator
  OrganisationCreateResponse,
  // First argument to the payload creator
  OrganisationCreateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "organisations/create",
  // Declare the type your function argument here:
  async (requestData: OrganisationCreateRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/v4/organisations/create`,
        {
          name: requestData.name,
          accounts: requestData.accounts,
          customer_sites: requestData.customer_sites,
          client_number: requestData.client_number,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        const errorResponse: ChiefAPIError = response.data;
        return rejectWithValue({
          error: errorResponse.detail[0].msg,
        } as APIError);
      }
      return response.data as OrganisationCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }

        const errorResponse: ChiefAPIError = err.response.data;
        return rejectWithValue({
          error: errorResponse.detail[0].msg,
        } as APIError);
      }

      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const updateOrganisation = createAsyncThunk<
  // Return type of the payload creator
  OrganisationCreateResponse,
  // First argument to the payload creator
  OrganisationUpdateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "organisations/update",
  // Declare the type your function argument here:
  async (requestData: OrganisationUpdateRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/v4/organisations/${requestData.id}/update`,
        {
          name: requestData.name,
          accounts: requestData.accounts,
          feature_flags: requestData.feature_flags,
          customer_sites: requestData.customer_sites,
          client_number: requestData.client_number,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        const errorResponse: ChiefAPIError = response.data;
        return rejectWithValue({
          error: errorResponse.detail[0].msg,
        } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data as OrganisationCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }

        const errorResponse: ChiefAPIError = err.response.data;
        return rejectWithValue({
          error: errorResponse.detail[0].msg,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const deleteOrganisation = createAsyncThunk<
  // Return type of the payload creator
  { success: boolean },
  // First argument to the payload creator
  OrganisationDeleteRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "organisations/delete",
  // Declare the type your function argument here:
  async (requestData: OrganisationDeleteRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/v3/organisations/${requestData.id}/delete`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 204) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return { success: true };
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const organisationsSlice = createSlice({
  name: "organisations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listOrganisations.fulfilled, (state, { payload }) => {
      state.organisations = payload;
    });
    builder.addCase(listOrganisations.rejected, (state, action) => {
      if (action.payload) {
        state.organisationError = action.payload.error;
      } else {
        state.organisationError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(createOrganisation.rejected, (state, action) => {
      if (action.payload) {
        state.organisationError = action.payload.error;
      } else {
        state.organisationError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(updateOrganisation.rejected, (state, action) => {
      if (action.payload) {
        state.organisationError = action.payload.error;
      } else {
        state.organisationError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(deleteOrganisation.rejected, (state, action) => {
      if (action.payload) {
        state.organisationError = action.payload.error;
      } else {
        state.organisationError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

export default organisationsSlice.reducer;
