import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./Login";
import AppBar from "./AppBar";
import Accounts from "./Accounts";
import Stores from "./ClientStores";
import Store from "./ClientStore";
import Status from "./Status";
import Account from "./Account";
import OrderStatus from "./OrderStatus";
import Organisations from "./Organisations";
import Organisation from "./Organisation";
import SyncingPage from "./SyncingPage";
import { useEffect, useState } from "react";
import { checkUserAccount } from "./reducers/user";
import { RootState, useAppDispatch as useDispatch } from "./store";
import TracifyIcon from "./assets/icon.png";
import { CircularProgress } from "@mui/material";

function Routing() {
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    dispatch(checkUserAccount({ backendUrl })).finally(() => {
      timeout = setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const account = useSelector((state: RootState) => state.user.account);
  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={"icon.png"}
            height={48}
            width={48}
            alt="Tracify Icon"
            style={{ marginBottom: 8 }}
          />
          <span style={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size={24} color="inherit" /> &nbsp;
            <p> Loading Theresa App</p>
          </span>
        </div>
      ) : (
        <>
          {account ? <AppBar /> : null}

          <Routes>
            {!account ? (
              <>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/organisations" element={<Login />} />
                <Route path="/organisation/:id" element={<Login />} />
                <Route path="/accounts" element={<Login />} />
                <Route path="/account/:acid" element={<Login />} />
                <Route path="/stores" element={<Login />} />
                <Route path="/store/:csid" element={<Login />} />
                <Route path="/status" element={<Login />} />
                <Route path="/syncing" element={<Login />} />
                <Route path="/orders" element={<Login />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Organisations />} />
                <Route path="/organisations" element={<Organisations />} />
                <Route path="/organisation/:id" element={<Organisation />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/account/:acid" element={<Account />} />
                <Route path="/stores" element={<Stores />} />
                <Route path="/store/:csid" element={<Store />} />
                <Route path="/status" element={<Status />} />
                <Route path="/syncing" element={<SyncingPage />} />
                <Route path="/orders" element={<OrderStatus />} />
              </>
            )}
          </Routes>
        </>
      )}
    </div>
  );
}

export default Routing;
