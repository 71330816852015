import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { store } from "./store";

// import Login from './Login';
import Routing from "./Routing";
import { CookiesProvider } from "react-cookie";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

// let store = createStore(environment);
// console.log(store.getState());

export default function App() {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <Router>
          <div>
            <ThemeProvider theme={darkTheme}>
              <CssBaseline enableColorScheme />
              <Routing />
            </ThemeProvider>
          </div>
        </Router>
      </Provider>
    </CookiesProvider>
  );
}
