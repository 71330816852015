import {
  Box,
  Paper,
  TextField,
  Typography,
  Alert,
  Collapse,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Switch,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  DialogActions,
  IconButton,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UpgradeIcon from "@mui/icons-material/Upgrade";

import { SetStateAction, useEffect, useMemo, useState } from "react";
import { batch, useSelector } from "react-redux";
import { RootState, useAppDispatch as useDispatch } from "./store";

import {
  Account,
  AccountEnableResponse,
  APIError,
  Store,
  ApprovalCreateResponse,
  AccountChangePasswordResponse,
  Organisation,
  OrganisationCreateResponse,
} from "./backendTypes";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeAccountPassword,
  changeAccountType,
  deleteAccount,
  enableAccount,
  listAccounts,
} from "./reducers/accounts";
import {
  createApproval,
  deleteApproval,
  deleteApprovalByAccountID,
  listApprovals,
} from "./reducers/approvals";
import TUtils from "./TUtils";
import { generatePassword } from "./Accounts";
import {
  listOrganisations,
  updateOrganisation,
} from "./reducers/organisations";

const headerStyle = {
  // paddingTop: '2em',
  // paddingBottom: '1em',
  margin: "0.5em",
  paddingLeft: "8px",
};

const defaultStyle = {
  paddingLeft: "16px",
  margin: "0.5em",
  // marginLeft: '1em',
};
function findAccount(
  acid: string | undefined,
  accounts: Array<Account> | undefined
) {
  if (accounts === undefined) return undefined;
  const a = accounts.filter((account) => account?.acid === acid);
  return a.length == 0 ? undefined : a[0];
}

function roleToName(role: number | string | undefined) {
  if (role === 255) return "admin";
  if (role === 128) return "master";
  return "user";
}

function slToName(role: number | string | undefined) {
  if (role === 255) return "enterprise";
  if (role === 128) return "premium";
  return "normal";
}

export const accessScopes = [
  {
    label: "Access to Beta environment",
    value: "access_beta_environment",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
      "kytron::grant_site_access",
    ],
  },
  {
    label: "Access to Dashboard",
    value: "access_rts_dashboard",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
      "kytron::grant_site_access",
    ],
  },
  {
    label: "Manage Organisations",
    value: "manage_organisations",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Add users to store",
    value: "kytron::grant_site_access",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Create Stores",
    value: "kytron::create_customersite",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Update Stores",
    value: "kytron::update_customersite",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Create Accounts",
    value: "kytron::create_account",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },

  {
    label: "Delete Stores",
    value: "kytron::delete_customersite",
    permissions: ["kytron::grant_super_admin_access"],
  },
  {
    label: "Delete Accounts",
    value: "kytron::delete_account",
    permissions: ["kytron::grant_super_admin_access"],
  },
  {
    label: "Update Currencies used for conversion",
    value: "kytron::update_currencies",
    permissions: ["kytron::grant_super_admin_access"],
  },
  {
    label: "Grant super admin access",
    value: "kytron::grant_super_admin_access",
    permissions: ["kytron::grant_super_admin_access"],
  },
  {
    label: "Grant admin access",
    value: "kytron::grant_admin_access",
    permissions: ["kytron::grant_super_admin_access"],
  },
];

const csAccessScopes = [
  {
    label: "Access to Beta environment",
    value: "access_beta_environment",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
      "kytron::grant_site_access",
    ],
  },
  {
    label: "Access to Dashboard",
    value: "access_rts_dashboard",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
      "kytron::grant_site_access",
    ],
  },
  {
    label: "Manage Organisations",
    value: "manage_organisations",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Add users to store",
    value: "kytron::grant_site_access",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Create Stores",
    value: "kytron::create_customersite",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Update Stores",
    value: "kytron::update_customersite",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
  {
    label: "Create Accounts",
    value: "kytron::create_account",
    permissions: [
      "kytron::grant_super_admin_access",
      "kytron::grant_admin_access",
    ],
  },
];

function AccountComponent() {
  const scope = "kytron::read_events";
  const manageOrganisationsApprovalScope = "manage_organisations";
  const deleteAccountScope = "kytron::delete_account";
  const { acid } = useParams();
  const navigate = useNavigate();
  const [loadingCsAgentUpgrade, setLoadingCsAgentUpgrade] = useState(false);
  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );

  const session = useSelector((state: RootState) => state.user.session);
  const account = useSelector((state: RootState) => state.user.account);
  const stores = useSelector((state: RootState) => state.stores.stores);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const approvals = useSelector(
    (state: RootState) => state.approvals.approvals
  );

  const selectedAccount = useSelector((state: RootState) =>
    findAccount(acid, state.accounts.accounts)
  );
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation | null>();

  useEffect(() => {
    if (accounts.length == 0) {
      dispatch(listAccounts({ session, backendUrl }));
    }
  }, []);

  useEffect(() => {
    if (organisations.length == 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
  }, []);

  useEffect(() => {
    if (approvals.length === 0) {
      dispatch(listApprovals({ session, backendUrl }));
    }
  }, []);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [addStoreDlgOpen, setAddStoreDlgOpen] = useState(false);
  const [addScopeDlgOpen, setAddScopeDlgOpen] = useState(false);
  const [addOrganisationDlgOpen, setAddOrganisationDlgOpen] = useState(false);
  const [changePasswordDlgOpen, setChangePasswordDlgOpen] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [deleteAccountDlgOpen, setDeleteAccountDlgOpen] = useState(false);
  const [selectedScope, setSelectedScope] = useState<
    (typeof accessScopes)[number] | null
  >(null);

  useEffect(() => {
    if (changePasswordDlgOpen) {
      setUserPassword(generatePassword());
    }
  }, [changePasswordDlgOpen]);

  const accountApprovalsWithoutStores = useMemo(
    () => approvals.filter((val) => val.acid === acid && val.scope !== scope),
    [approvals, acid]
  );
  const highestGrantApproval = useMemo(() => {
    const superAdmin = account?.scp?.any?.find(
      (val) => val === "kytron::grant_super_admin_access"
    );
    if (superAdmin) return superAdmin;
    const admin = account?.scp?.any?.find(
      (val) => val === "kytron::grant_admin_access"
    );
    if (admin) return admin;
    const support = account?.scp?.any?.find(
      (val) => val === "kytron::grant_site_access"
    );
    if (support) return support;
    return "";
  }, [account, acid]);
  const relevantStoreApprovals = approvals.filter(
    (x) => x.acid === selectedAccount?.acid
  );

  const relevantOrganisations = useMemo(() => {
    if (organisations && selectedAccount) {
      return (
        organisations.filter((orga) =>
          orga.accounts.find((el) => el.id === selectedAccount?.acid)
        ) ?? []
      );
    } else return [];
  }, [organisations, selectedAccount]);

  const organisationToAdd = useMemo(() => {
    const addedOrgas = relevantOrganisations.map((el) => el.id);
    return organisations.filter((el) => !addedOrgas.includes(el.id)) ?? [];
  }, [organisations, relevantOrganisations]);

  useEffect(() => {
    if (organisationToAdd && selectedAccount) {
      const organisation = organisationToAdd.find((orga) =>
        orga.accounts.find((el) => el.id === selectedAccount?.acid)
      );
      setSelectedOrganisation(organisation ?? null);
    }
  }, [organisationToAdd, selectedAccount]);

  const relevantStores: Array<Store & { asis: string[] }> = [];
  const hasAllCSAgentScopes = csAccessScopes.reduce((prev, curr) => {
    if (!prev) return false;
    const hasApproval = approvals.find(
      (el) => el.acid === selectedAccount?.acid && el.scope === curr.value
    );
    return Boolean(hasApproval);
  }, true);

  stores.forEach((store) => {
    const relevantAppr = relevantStoreApprovals.filter(
      (appr) => appr.rsid === store.csid
    );
    if (!!relevantAppr.length) {
      relevantStores.push({
        ...store,
        asis: relevantAppr.map((appr) => appr.asi),
      });
    }
  });
  const manageOrgasScope = useMemo(
    () =>
      !!account?.scp.any?.find((el) => el === manageOrganisationsApprovalScope),
    [account]
  );

  const deleteAccountApproval = useMemo(
    () => !!account?.scp.any?.find((el) => el === deleteAccountScope),
    [account]
  );

  const relevantAddStores = stores.filter(
    (store) => !relevantStores.map((el) => el.csid).includes(store.csid)
  );
  const onPasswordInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setUserPassword(event.target.value);
  };
  const handleUpdate = () => {
    dispatch(listAccounts({ session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
    dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
  };

  const handleEnableAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;
    if (selectedAccount == undefined) {
      setErrorMessage(
        `No account has been selected! Unable to set account status!`
      );
      return;
    }
    dispatch(
      enableAccount({
        acid: selectedAccount.acid,
        active: newState,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: AccountEnableResponse) => {
        setSuccessMessage(
          `Account '${selectedAccount.email}' has been set '${
            newState ? "active" : "inactive"
          }' (${createResponse.acid})`
        );
        dispatch(listAccounts({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to change account '${selectedAccount.email}': ${err.error}`
        );
      });
  };

  /**
   * Function to upgrade given account id to a master account
   *
   * We delete all old approvals first and add an unpermissions approval.
   * @param {string} acid Account id
   */
  const upgradeToMaster = (acid: string) => {
    if (acid === undefined) return;
    dispatch(
      deleteApprovalByAccountID({
        acid: acid,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          createApproval({
            acid: acid,
            scope: "kytron::read_events",
            rsid: undefined,
            rstype: 0,
            session,
            backendUrl,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              createApproval({
                acid: acid,
                scope: manageOrganisationsApprovalScope,
                rsid: undefined,
                rstype: 0,
                session,
                backendUrl,
              })
            )
              .unwrap()
              .then(() => {
                dispatch(
                  changeAccountType({
                    acid: acid,
                    service_level: 128,
                    role: 128,
                    session,
                    backendUrl,
                  })
                )
                  .unwrap()
                  .then(() => {
                    handleUpdate();
                    setSuccessMessage(
                      `Account with id '${acid}' has been upgraded to master account.`
                    );
                  });
              });
          });
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to upgrade account with id '${acid}': ${err.error}`
        );
      });
  };

  const upgradeToCSAgent = async (acid: string) => {
    if (acid === undefined) return;
    setErrorMessage("");
    setSuccessMessage("");
    setLoadingCsAgentUpgrade(true);
    let hasError = false;
    for (const scope of csAccessScopes) {
      const currentApproval = approvals.find(
        (approval) => approval.acid === acid && approval.scope === scope.value
      );
      if (!currentApproval) {
        try {
          await dispatch(
            createApproval({
              acid: acid,
              scope: scope.value,
              rsid: undefined,
              rstype: 0,
              session,
              backendUrl,
            })
          ).unwrap();
        } catch (error: any) {
          hasError = true;
          setErrorMessage(
            `Unable to set all customer super agent access scopes for account with id '${acid}': ${error.error}`
          );
        }
      }
    }
    try {
      await dispatch(
        changeAccountType({
          acid: acid,
          service_level: 128,
          role: 128,
          session,
          backendUrl,
        })
      ).unwrap();
    } catch (error: any) {
      hasError = true;
      setErrorMessage(
        `Unable to upgrade account with id to a master user '${acid}': ${error.error}`
      );
    }
    if (!hasError) {
      setSuccessMessage(
        `Successfully set all customer super agent access scopes for account with id '${acid}'`
      );
    }
    setLoadingCsAgentUpgrade(false);
    handleUpdate();
  };

  /**
   * Downgrades given account to a user account
   * @param {string} acid Account id
   */
  const downgradeToUser = (acid: string) => {
    if (acid === undefined) return;
    dispatch(
      deleteApprovalByAccountID({
        acid: acid,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          changeAccountType({
            acid: acid,
            service_level: 2,
            role: 2,
            session,
            backendUrl,
          })
        )
          .unwrap()
          .then(() => {
            handleUpdate();
            setSuccessMessage(
              `Account with id '${acid}' has been downgraded to user account.`
            );
          })
          .catch((err: APIError) => {
            setErrorMessage(
              `Unable to downgrade account with id '${acid}': ${err.error}`
            );
          });
      });
  };

  const removeApproval = (asis: Array<string>, scope?: string) => {
    if (acid === undefined) return;
    dispatch(
      deleteApproval({
        asis: asis,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        handleUpdate();
        setSuccessMessage(
          `Account with id '${acid}' has lost approval '${scope}'.`
        );
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to upgrade account with id '${acid}': ${err.error}`
        );
      });
  };

  const handleAddOrganisation = () => {
    setAddOrganisationDlgOpen(true);
  };

  const handleAddOrganisationClose = () => {
    setAddOrganisationDlgOpen(false);
  };

  const handleAddStore = () => {
    setAddStoreDlgOpen(true);
  };

  const handleAddStoreClose = () => {
    setAddStoreDlgOpen(false);
  };

  const handleAddScope = () => {
    setAddScopeDlgOpen(true);
  };

  const handleAddScopeClose = () => {
    setSelectedScope(null);
    setAddScopeDlgOpen(false);
  };

  const handleApproveStoreAccess = () => {
    if (!selectedStore || selectedAccount == undefined) {
      setErrorMessage(
        `No account or store has been selected! Unable to approve account!`
      );
      setAddStoreDlgOpen(false);
      return;
    }
    dispatch(
      createApproval({
        acid: selectedAccount.acid,
        scope: "kytron::read_events",
        rsid: selectedStore?.csid,
        rstype: 0,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: ApprovalCreateResponse) => {
        setAddStoreDlgOpen(false);
        setSuccessMessage(
          `Account with email '${selectedAccount.email}' was approved to read from store '${selectedStore?.name}' (${createResponse.asi})`
        );
        dispatch(listApprovals({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to approve account with email '${selectedAccount.email}': ${err.error}`
        );
      });
  };
  const handleAddAccountToOrganisationStores = (storeIds: string[]) => {
    if (storeIds.length > 0 && selectedAccount) {
      batch(() => {
        storeIds.map((el) => {
          dispatch(
            createApproval({
              acid: selectedAccount.acid,
              scope: "kytron::read_events",
              rsid: el,
              rstype: 0,
              session,
              backendUrl,
            })
          );
        });
      });
    }
  };

  const handleAddScopes = async () => {
    if (!selectedScope || !selectedAccount) {
      setErrorMessage(`No scope has been selected`);
      setAddStoreDlgOpen(false);
      return;
    }
    dispatch(
      createApproval({
        acid: selectedAccount.acid,
        scope: selectedScope.value,
        rsid: undefined,
        rstype: 0,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: ApprovalCreateResponse) => {
        setAddStoreDlgOpen(false);
        setSuccessMessage(
          `Scope ${selectedScope.value} has been added to account with email '${selectedAccount.email}' (${createResponse.asi})`
        );
        dispatch(listApprovals({ session, backendUrl }));
        handleAddScopeClose();
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to approve account with email '${selectedAccount.email}': ${err.error}`
        );
      });
  };

  const handleAddOrganisations = () => {
    if (selectedOrganisation && selectedAccount) {
      // we changed the organisation for this store
      dispatch(
        updateOrganisation({
          id: selectedOrganisation.id,
          name: selectedOrganisation?.name,
          client_number: selectedOrganisation?.client_number,
          feature_flags: selectedOrganisation?.feature_flags,
          accounts: [
            ...(selectedOrganisation?.accounts?.map((el) => ({
              id: el.id,
            })) ?? []),
            { id: selectedAccount?.acid },
          ],
          customer_sites:
            selectedOrganisation.customer_sites?.map((el) => ({ id: el.id })) ??
            [],
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then((createResponse: OrganisationCreateResponse) => {
          handleAddAccountToOrganisationStores(
            selectedOrganisation.customer_sites.map((el) => el.id)
          );
          setAddOrganisationDlgOpen(false);
          handleUpdate();
          setSuccessMessage(
            `Account added to organisation '${selectedOrganisation?.name}' with id '${createResponse.id}'`
          );
        })
        .catch((err: APIError) => {
          setErrorMessage(
            `Unable to remove account from organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  const handleRemoveAccountFromOrganisationStores = (storeIds: string[]) => {
    const storeApprovals = approvals
      .filter((el) => {
        return (
          el.scope === "kytron::read_events" &&
          el.acid === selectedAccount?.acid &&
          storeIds.includes(el.rsid ?? "")
        );
      })
      .map((el) => el.asi);
    if (storeApprovals.length > 0) {
      dispatch(
        deleteApproval({
          asis: storeApprovals,
          session,
          backendUrl,
        })
      );
    }
  };

  const handleRemoveOrganisation = (id: string) => {
    const relevantOrganisation = relevantOrganisations.find(
      (el) => el.id === id
    );
    if (!id || !relevantOrganisation?.id) {
      return;
    }
    if (relevantOrganisation && selectedAccount) {
      // we changed the organisation for this store
      const indexInOrga = relevantOrganisation.accounts.findIndex(
        (el) => el.id === selectedAccount.acid
      );
      const removedAccounts =
        indexInOrga !== -1
          ? [
              ...relevantOrganisation.accounts.slice(0, indexInOrga),
              ...relevantOrganisation.accounts.slice(indexInOrga + 1),
            ]
          : relevantOrganisation.accounts;
      dispatch(
        updateOrganisation({
          id: relevantOrganisation.id,
          name: relevantOrganisation?.name,
          client_number: relevantOrganisation?.client_number,
          feature_flags: relevantOrganisation?.feature_flags,
          accounts: removedAccounts,
          customer_sites:
            relevantOrganisation.customer_sites?.map((el) => ({
              id: el.id,
            })) ?? [],
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then(() => {
          handleRemoveAccountFromOrganisationStores(
            relevantOrganisation.customer_sites.map((el) => el.id)
          );
          setAddOrganisationDlgOpen(false);
          handleUpdate();
          setSuccessMessage(
            `Account removed from organisation '${selectedOrganisation?.name}'`
          );
        })
        .catch((err: APIError) => {
          setErrorMessage(
            `Unable to remove account to organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  const handleChangePassword = () => {
    if (selectedAccount == undefined) {
      setErrorMessage(
        `No account has been selected! Unable to change Password!`
      );
      setChangePasswordDlgOpen(false);
      return;
    }
    if (userPassword?.length < 8) {
      setErrorMessage(
        `Selected Password is to short! Please type at least 8 characters!`
      );
      setChangePasswordDlgOpen(false);
      return;
    }
    dispatch(
      changeAccountPassword({
        acid: selectedAccount.acid,
        password: TUtils.hash(userPassword),
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: AccountChangePasswordResponse) => {
        setChangePasswordDlgOpen(false);
        if (createResponse.acid) {
          setSuccessMessage(
            `Password for account with email '${selectedAccount.email}' has been changed to '${userPassword}'`
          );
          navigator.clipboard.writeText(
            `Email: '${selectedAccount.email}'\nPassword: ${userPassword}`
          );
          handleUpdate();
        } else {
          setErrorMessage(
            `Password for Account '${selectedAccount.email}' could not be changed!`
          );
        }
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to change password for Account '${selectedAccount.email}': ${err.error}`
        );
      });
  };

  const handleDeleteAccount = () => {
    setErrorMessage("");
    setDeleteAccountDlgOpen(true);
  };

  const handleDeleteAccountClose = () => {
    setDeleteAccountDlgOpen(false);
  };

  const handleDeleteAccountAction = () => {
    if (!selectedAccount) return;

    dispatch(
      deleteAccount({
        acid: selectedAccount.acid,
        force: true,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        handleUpdate();
        navigate(`/accounts`);
        // setDeleteAccountDlgOpen(false);
        // setSuccessMessage(
        //   `Account '${selectedAccount?.description}' was successfully deleted`
        // );
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to delete account '${selectedAccount.email}': ${err.error}`
        );
      });
  };

  return (
    <div>
      <div className="centered">
        <Box sx={{ width: "100%" }}>
          <Collapse in={successMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="success"
              onClose={() => {
                setSuccessMessage("");
              }}
            >
              {successMessage}
            </Alert>
          </Collapse>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              {selectedAccount?.email}
            </Typography>
            <div className="boxb">
              <TextField
                disabled
                id="outlined-disabled"
                label="Account-ID"
                value={selectedAccount?.acid}
                style={defaultStyle}
                sx={{ width: "50vh" }}
              />
            </div>
            <div className="boxb">
              <TextField
                disabled
                id="outlined-disabled"
                label="Role"
                value={roleToName(selectedAccount?.role)}
                style={defaultStyle}
                sx={{ width: "50vh" }}
              />
            </div>
            <div className="boxb">
              <TextField
                disabled
                id="outlined-disabled"
                label="Service-Level"
                value={slToName(selectedAccount?.service_level)}
                style={defaultStyle}
                sx={{ width: "50vh" }}
              />
            </div>
            <div className="boxb">
              <FormGroup sx={{ margin: "2em" }}>
                <FormLabel id="active-label">Account status:</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedAccount?.active}
                      onChange={handleEnableAccount}
                      name="active"
                    />
                  }
                  label="Active"
                  sx={{ width: "100px" }}
                />
              </FormGroup>
            </div>
            <div className="boxb">
              {Boolean(selectedAccount?.acid) && !hasAllCSAgentScopes ? (
                <Button
                  aria-label="add"
                  startIcon={<UpgradeIcon />}
                  sx={{ paddingTop: "1em", marginLeft: "2em" }}
                  onClick={() => upgradeToCSAgent(selectedAccount?.acid ?? "")}
                  disabled={loadingCsAgentUpgrade}
                >
                  {loadingCsAgentUpgrade
                    ? "Setting Scopes..."
                    : "Set all customer support agent scopes"}
                </Button>
              ) : null}
            </div>
            <div className="boxb">
              {selectedAccount?.role == 128 && (
                <Button
                  aria-label="add"
                  startIcon={<KeyboardArrowDownIcon />}
                  sx={{ paddingTop: "1em", marginLeft: "2em" }}
                  onClick={() => downgradeToUser(selectedAccount?.acid)}
                >
                  Downgrade to user
                </Button>
              )}
            </div>
            <div className="boxb">
              {selectedAccount?.role && selectedAccount?.role < 128 && (
                <Button
                  aria-label="add"
                  startIcon={<UpgradeIcon />}
                  sx={{ paddingTop: "1em", marginLeft: "2em" }}
                  onClick={() => upgradeToMaster(selectedAccount?.acid)}
                >
                  Upgrade to master
                </Button>
              )}
              <Button
                aria-label="change-password"
                onClick={() => setChangePasswordDlgOpen(true)}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ paddingTop: "1em", marginLeft: "2em" }}
              >
                Change Password
              </Button>
              <Dialog
                open={changePasswordDlgOpen}
                onClose={() => setChangePasswordDlgOpen(false)}
              >
                <DialogTitle>Change Account Password</DialogTitle>
                <DialogContent sx={{ minHeight: "50vh", minWidth: "60vh" }}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="password"
                    label="New Password"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={userPassword}
                    onChange={onPasswordInputChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setChangePasswordDlgOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleChangePassword}
                    disabled={userPassword.length < 8}
                  >
                    Change Password
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, paddingTop: "1em" }}
                style={headerStyle}
              >
                Access Scopes
              </Typography>
            </div>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Scope-ID</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Scope</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Op</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {accountApprovalsWithoutStores &&
                      accountApprovalsWithoutStores.map((approval) => (
                        <TableRow
                          key={approval?.asi}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {approval?.asi}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {accessScopes.find(
                              (el) => el.value === approval?.scope
                            )?.label ?? approval?.scope}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {approval?.scope}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {approval?.rstype}
                          </TableCell>

                          <TableCell align="right">
                            <Tooltip
                              title={`Revoke access scope ${approval?.scope}`}
                            >
                              <IconButton
                                disabled={
                                  !accessScopes
                                    .find((el) => el.value === approval.scope)
                                    ?.permissions?.includes(
                                      highestGrantApproval
                                    )
                                }
                                onClick={() =>
                                  approval?.asi &&
                                  removeApproval(
                                    [approval?.asi],
                                    approval.scope
                                  )
                                }
                              >
                                <PersonRemoveIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                aria-label="add"
                onClick={handleAddScope}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ paddingTop: "1em" }}
              >
                Add Access Scope
              </Button>
              <Dialog open={addScopeDlgOpen} onClose={handleAddScopeClose}>
                <DialogTitle>Add new access scopes</DialogTitle>
                <DialogContent sx={{ minHeight: "50vh", minWidth: "60vh" }}>
                  <Autocomplete
                    disablePortal
                    id="access-scopes-index"
                    options={accessScopes.filter(
                      (el) =>
                        el.permissions.includes(highestGrantApproval) &&
                        accountApprovalsWithoutStores.findIndex(
                          (approval) => approval.scope === el.value
                        ) === -1
                    )}
                    sx={{ width: "90%" }}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, selection) => {
                      return option.value === selection?.value;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Access Scopes"
                        variant="standard"
                      />
                    )}
                    style={defaultStyle}
                    value={selectedScope}
                    onChange={(_event: any, newValue: any) =>
                      setSelectedScope(newValue)
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddScopeClose}>Cancel</Button>
                  <Button onClick={handleAddScopes} disabled={!selectedScope}>
                    Add Scope
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, paddingTop: "1em" }}
                style={headerStyle}
              >
                Organisations
              </Typography>
            </div>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Organisation-ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Op</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relevantOrganisations &&
                      relevantOrganisations.map((relevantOrganisation) => (
                        <TableRow
                          key={relevantOrganisation?.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {relevantOrganisation?.id}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantOrganisation?.name}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip
                              title={`Remove account from organisation ${relevantOrganisation?.name}`}
                            >
                              <PersonRemoveIcon
                                onClick={() =>
                                  relevantOrganisation?.id &&
                                  handleRemoveOrganisation(
                                    relevantOrganisation?.id
                                  )
                                }
                                cursor="pointer"
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {manageOrgasScope ? (
                <Button
                  aria-label="add"
                  onClick={handleAddOrganisation}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{ paddingTop: "1em" }}
                >
                  Add Organisations
                </Button>
              ) : null}
              <Dialog
                open={addOrganisationDlgOpen}
                onClose={handleAddOrganisationClose}
              >
                <DialogTitle>Add organisations to account</DialogTitle>
                <DialogContent sx={{ minHeight: "50vh", minWidth: "60vh" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-stores"
                    options={organisationToAdd}
                    sx={{ width: "100%", paddingRight: "2em" }}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => (
                      <TextField {...params} label="Organisations" />
                    )}
                    style={defaultStyle}
                    value={selectedOrganisation}
                    onChange={(_event: any, newValue: Organisation | null) =>
                      setSelectedOrganisation(newValue)
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddOrganisationClose}>Cancel</Button>
                  <Button onClick={handleAddOrganisations}>Add</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, paddingTop: "1em" }}
                style={headerStyle}
              >
                Approved Stores
              </Typography>
            </div>
            <div className="boxb">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, width: "90%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Store-ID</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Cluster</TableCell>
                      <TableCell>URL</TableCell>
                      <TableCell>Store</TableCell>
                      <TableCell align="right">Op</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relevantStores &&
                      relevantStores.map((relevantStore) => (
                        <TableRow
                          key={relevantStore?.csid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {relevantStore?.csid}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantStore?.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantStore?.pg_idx}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantStore?.url}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {relevantStore?.description}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip
                              title={`Revoke access to store ${relevantStore?.name}`}
                            >
                              <PersonRemoveIcon
                                onClick={() =>
                                  relevantStore?.asis &&
                                  removeApproval(relevantStore?.asis, scope)
                                }
                                cursor="pointer"
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                aria-label="add"
                onClick={handleAddStore}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ paddingTop: "1em" }}
              >
                Add Store
              </Button>
              <Dialog open={addStoreDlgOpen} onClose={handleAddStoreClose}>
                <DialogTitle>Approve store access</DialogTitle>
                <DialogContent sx={{ minHeight: "50vh", minWidth: "60vh" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-stores"
                    options={relevantAddStores}
                    sx={{ width: "100%", paddingRight: "2em" }}
                    getOptionLabel={(option) => `${option.name} ${option.url}`}
                    renderInput={(params) => (
                      <TextField {...params} label="Store" />
                    )}
                    style={defaultStyle}
                    value={selectedStore}
                    onChange={(_event: any, newValue: Store | null) =>
                      setSelectedStore(newValue)
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddStoreClose}>Cancel</Button>
                  <Button
                    onClick={handleApproveStoreAccess}
                    disabled={!selectedStore}
                  >
                    Approve
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            {deleteAccountApproval ? (
              <div style={{ paddingBottom: 48 }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, paddingBottom: 1 }}
                  style={headerStyle}
                >
                  Danger Zone
                </Typography>
                <div className="boxb">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteAccount}
                  >
                    Delete Account
                  </Button>
                  <Dialog
                    open={deleteAccountDlgOpen}
                    onClose={handleDeleteAccountClose}
                  >
                    <DialogTitle>
                      Delete account {selectedAccount?.email ?? ""}
                    </DialogTitle>
                    <DialogContent>
                      <p>
                        Are you sure you want to delete this account:{" "}
                        <b>{selectedAccount?.email ?? ""}</b>
                      </p>
                      <p> This action can't be reversed.</p>
                      {errorMessage ? (
                        <Alert
                          variant="outlined"
                          severity="error"
                          onClose={() => {
                            setErrorMessage("");
                          }}
                        >
                          {errorMessage}
                        </Alert>
                      ) : null}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDeleteAccountClose}>Cancel</Button>
                      <Button
                        onClick={handleDeleteAccountAction}
                        color="error"
                        variant="contained"
                      >
                        Delete Account
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            ) : null}
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default AccountComponent;
