import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtDecode from "jwt-decode";
import TUtils from "../TUtils";
import {
  LoginRequest,
  APIError,
  UserSession,
  TracifyJWTPayload,
} from "../backendTypes";
import { DateTime } from "luxon";

const initialState: {
  session: string;
  account: TracifyJWTPayload | null;
  error: string;
} = {
  session: "",
  account: null,
  error: "",
};

type UserInfoRequest = {
  backendUrl: string;
};

export const checkUserAccount = createAsyncThunk<
  // Return type of the payload creator
  UserSession,
  // First argument to the payload creator
  UserInfoRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "user/checkUserAccount",
  // Declare the type your function argument here:
  async (requestData: UserInfoRequest, { rejectWithValue }) => {
    const token = window.localStorage.getItem("token");
    if (!token) return rejectWithValue({ error: "No token found" } as APIError);

    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.get(
        `${requestData.backendUrl}/account/info`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": token,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }

      if (
        !response.data.result.valid ||
        (response.data.result?.expires &&
          response.data.result?.expires < DateTime.now())
      ) {
        throw new Error("Token is invalid. Please sign in again.");
      }

      const payload = response.data.result.payload;

      if (!payload) {
        throw new Error("Session token could not be decoded");
      }

      const data = {
        session: token,
        account: payload,
      };

      // Inferred return type: Promise<MyData>
      return data as UserSession;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const login = createAsyncThunk<
  // Return type of the payload creator
  UserSession,
  // First argument to the payload creator
  LoginRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "user/login",
  // Declare the type your function argument here:
  async (loginData: LoginRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${loginData.backendUrl}/account/login`,
        {
          email: loginData.email,
          password: TUtils.hash(loginData.password),
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }

      const payload = jwtDecode(
        response.data.result.session
      ) as TracifyJWTPayload;

      if (!payload) {
        throw new Error("Session token could not be decoded");
      }

      window.localStorage.setItem("token", response.data.result.session);

      const data = {
        ...response.data.result,
        account: payload,
      };

      // Inferred return type: Promise<MyData>
      return data as UserSession;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.session = payload.session;
      state.account = payload.account;
    });
    builder.addCase(checkUserAccount.fulfilled, (state, { payload }) => {
      state.session = payload.session;
      state.account = payload.account;
    });
    builder.addCase(login.rejected, (state, action) => {
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.error = action.payload.error;
      } else {
        state.error = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

// export const { login } = userSlice.actions;

export default userSlice.reducer;
