import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Store,
  StoreCreateRequest,
  StoreCreateResponse,
  StoreListRequest,
  APIError,
  HeartbeatResponse,
  StoreHeartbeatRequest,
  StoreDeleteResponse,
  StoreDeleteRequest,
  StoreServerTokenCreateResponse,
  StoreServerTokenCreateRequest,
  StoreUpdateRequest,
} from "../backendTypes";

const initialState = {
  stores: [] as Array<Store>,
  storeError: "",
};

export const listStores = createAsyncThunk<
  // Return type of the payload creator
  Array<Store>,
  // First argument to the payload creator
  StoreListRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "stores/list",
  // Declare the type your function argument here:
  async (requestData: StoreListRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/site/list`,
        {
          scope: requestData.scope,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result.sites as Array<Store>;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const getHeartbeat = createAsyncThunk<
  // Return type of the payload creator
  HeartbeatResponse,
  // First argument to the payload creator
  StoreHeartbeatRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "stores/heartbeat",
  // Declare the type your function argument here:
  async (requestData: StoreHeartbeatRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/site/heartbeat`,
        {
          csids: requestData.csids,
          num_entries: requestData.num_entries,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as HeartbeatResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const createStore = createAsyncThunk<
  // Return type of the payload creator
  StoreCreateResponse,
  // First argument to the payload creator
  StoreCreateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "stores/create",
  // Declare the type your function argument here:
  async (requestData: StoreCreateRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${requestData.backendUrl}/site/create`,
        {
          name: requestData.name,
          description: requestData.description,
          url: requestData.url,
          refs: requestData.refs,
          shop_system: requestData.shop_system,
          ...(requestData.redis_idx >= 0 && {
            redis_idx: requestData.redis_idx,
          }),
          ...(requestData.pg_idx >= 0 && {
            pg_idx: requestData.pg_idx,
          }),
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as StoreCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const updateStore = createAsyncThunk<
  // Return type of the payload creator
  StoreCreateResponse,
  // First argument to the payload creator
  StoreUpdateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "stores/update",
  // Declare the type your function argument here:
  async (requestData: StoreUpdateRequest, { rejectWithValue }) => {
    const { csid, name, description, url, redis_idx, pg_idx, shop_system } =
      requestData;
    if (
      !csid ||
      !name ||
      !description ||
      !url ||
      redis_idx === undefined ||
      redis_idx === -1 ||
      pg_idx === undefined ||
      pg_idx === -1 ||
      !shop_system
    ) {
      rejectWithValue({
        error: "Missing values for updating store",
      } as APIError);
    }

    try {
      const response = await axios.post(
        `${requestData.backendUrl}/site/update`,
        {
          csid: requestData.csid,
          name: requestData.name,
          description: requestData.description,
          url: requestData.url,
          shop_system: requestData.shop_system,
          active: requestData.active,
          // we don't send the pg_idx and redis_idx to the update endpoint
          // to avoid an accidental updates of the cluster
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as StoreCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const deleteStore = createAsyncThunk<
  // Return type of the payload creator
  StoreDeleteResponse,
  // First argument to the payload creator
  StoreDeleteRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "stores/delete",
  // Declare the type your function argument here:
  async (requestData: StoreDeleteRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/site/delete`,
        {
          csid: requestData.csid,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as StoreDeleteResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const createStoreServerToken = createAsyncThunk<
  // Return type of the payload creator
  StoreServerTokenCreateResponse,
  // First argument to the payload creator
  StoreServerTokenCreateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "stores/create",
  // Declare the type your function argument here:
  async (requestData: StoreServerTokenCreateRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/v4/customer_sites/${requestData.csid}/token/create`,
        { id: requestData.id },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 201) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data as StoreServerTokenCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listStores.fulfilled, (state, { payload }) => {
      state.stores = payload;
    });
    builder.addCase(listStores.rejected, (state, action) => {
      if (action.payload) {
        state.storeError = action.payload.error;
      } else {
        state.storeError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(getHeartbeat.rejected, (state, action) => {
      if (action.payload) {
        state.storeError = action.payload.error;
      } else {
        state.storeError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(createStore.rejected, (state, action) => {
      if (action.payload) {
        state.storeError = action.payload.error;
      } else {
        state.storeError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(updateStore.rejected, (state, action) => {
      if (action.payload) {
        state.storeError = action.payload.error;
      } else {
        state.storeError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

// export const { login } = storesSlice.actions;

export default storesSlice.reducer;
