import { AnyAction, CombinedState, combineReducers } from "redux";
import environment from "./environment";
import user from "./user";
import accounts from "./accounts";
import stores from "./stores";
import organisations from "./organisations";
import approvals from "./approvals";
import siterefs from "./siterefs";
import integrations from "./integrations";
import {
  Account,
  Store,
  Approval,
  Siteref,
  Organisation,
  TracifyJWTPayload,
  Integration,
  AdAccount,
} from "../backendTypes";
import adAccounts from "./ad-accounts";

const reduce = combineReducers({
  environment,
  user,
  accounts,
  stores,
  organisations,
  approvals,
  siterefs,
  integrations,
  adAccounts,
});

const rootReducer = (
  state:
    | CombinedState<{
        environment: {
          backendUrl: string;
          chiefBackendUrl: string;
          shopconnectorBackendUrl: string;
          adconnectorBackendUrl: string;
        };
        user: {
          session: string;
          account: TracifyJWTPayload | null;
          error: string;
        };
        accounts: { accounts: Account[]; accountError: string };
        stores: { stores: Store[]; storeError: string };
        organisations: {
          organisations: Organisation[];
          organisationError: string;
        };
        integrations: {
          integrations: Integration[];
          integrationError: string;
        };
        approvals: { approvals: Approval[]; approvalError: string };
        siterefs: { siterefs: Siteref[]; siterefError: string };
        adAccounts: { adAccounts: AdAccount[]; adAccountError: string };
      }>
    | undefined,
  action: AnyAction
) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return reduce(state, action);
};

export default rootReducer;
