import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  Typography,
  Button,
  TableCell,
  TableRow,
  Alert,
  Collapse,
  Input,
} from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getHeartbeat, listStores } from "./reducers/stores";
import { RootState, useAppDispatch as useDispatch } from "./store";
import TUtils from "./TUtils";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  APIError,
  AttributionInfo,
  EventInfo,
  HeartbeatResponse,
  Store,
} from "./backendTypes";
import { createTable, HeadCell, Order } from "./tableUtils";

const headerStyle = {
  paddingTop: "2em",
  paddingBottom: "1em",
  paddingLeft: "22px",
};

const defaultStyle = {
  paddingLeft: "16px",
};

const searchBoxStyle = {
  paddingLeft: "16px",
};

const searchInputStyle = {
  paddingLeft: "8px",
};

function qualifiedStoreName(store: Store) {
  const name =
    store.url.length > 0
      ? `${store.name} - ${store.description} (${store.url})`
      : `${store.name} - ${store.description}`;
  return name + ` - cluster ${store.pg_idx + 1}`;
}

const eventHeadCells: HeadCell<EventInfo>[] = [
  {
    id: "eid",
    numeric: false,
    disablePadding: false,
    label: "Event-ID",
  },
  {
    id: "event",
    numeric: false,
    disablePadding: false,
    label: "Timestamp",
  },
  {
    id: "origin",
    numeric: false,
    disablePadding: false,
    label: "Origin",
  },
  {
    id: "interaction",
    numeric: false,
    disablePadding: false,
    label: "Interaction",
  },
  {
    id: "nitems",
    numeric: true,
    disablePadding: false,
    label: "Num(items)",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
];

const attributionHeadCells: HeadCell<AttributionInfo>[] = [
  {
    id: "aid",
    numeric: false,
    disablePadding: false,
    label: "Attribution-ID",
  },
  {
    id: "event",
    numeric: false,
    disablePadding: false,
    label: "Timestamp",
  },
  {
    id: "adcid",
    numeric: false,
    disablePadding: false,
    label: "Campaign-ID",
  },
  {
    id: "adgid",
    numeric: false,
    disablePadding: false,
    label: "Adset-ID",
  },
  {
    id: "adid",
    numeric: false,
    disablePadding: false,
    label: "Ad-ID",
  },
  {
    id: "source",
    numeric: false,
    disablePadding: false,
    label: "Source",
  },
  {
    id: "medium",
    numeric: false,
    disablePadding: false,
    label: "Medium",
  },
];

function Status() {
  const scope = "kytron::read_events";

  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );

  const session = useSelector((state: RootState) => state.user.session);
  const stores = useSelector((state: RootState) => state.stores.stores);

  useEffect(() => {
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
  }, []);

  const [events, setEvents] = useState<Array<EventInfo>>([]);
  const [eventRows, setEventRows] = useState<Array<EventInfo>>([]);

  const [eventOrder, setEventOrder] = useState<Order>("desc");
  const [eventOrderBy, setEventOrderBy] = useState<keyof EventInfo>("event");
  const [eventsPage, setEventsPage] = useState(0);
  const [eventRowsPerPage, setEventRowsPerPage] = useState(5);

  const [attributions, setAttributions] = useState<Array<AttributionInfo>>([]);
  const [attributionRows, setAttributionRows] = useState<
    Array<AttributionInfo>
  >([]);

  const [attributionOrder, setAttributionOrder] = useState<Order>("desc");
  const [attributionOrderBy, setAttributionOrderBy] =
    useState<keyof AttributionInfo>("event");
  const [attributionsPage, setAttributionsPage] = useState(0);
  const [attributionRowsPerPage, setAttributionRowsPerPage] = useState(5);

  // const [selectedStore, setSelectedStore] = useState<Store | null>();
  const [selectedStoreName, setSelectedStoreName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [eventSearchText, setEventSearchText] = useState("");
  const [attributionSearchText, setAttributionSearchText] = useState("");

  const handleUpdate = () => {
    const selectedStores = stores.filter(
      (store) => qualifiedStoreName(store) === selectedStoreName
    );
    const csids = selectedStores.map((x) => x.csid);
    dispatch(
      getHeartbeat({
        csids,
        num_entries: 50,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((heartbeatResponse: HeartbeatResponse) => {
        setEvents(heartbeatResponse.events ?? []);
        setAttributions(heartbeatResponse.attributions ?? []);
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to retrieve heartbeat for store with csid '${selectedStoreName}': ${err.error}`
        );
      });
  };

  useEffect(() => {
    if (eventSearchText.length !== 0) {
      setEventRows(TUtils.filterArrayByString(events, eventSearchText));
      setEventsPage(0);
    } else {
      setEventRows(events);
    }
  }, [events, eventSearchText]);

  useEffect(() => {
    if (attributionSearchText.length !== 0) {
      setAttributionRows(
        TUtils.filterArrayByString(attributions, attributionSearchText)
      );
      setAttributionsPage(0);
    } else {
      setAttributionRows(attributions);
    }
  }, [attributions, attributionSearchText]);

  const handleEventsRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof EventInfo
  ) => {
    const isAsc = eventOrderBy === property && eventOrder === "asc";
    setEventOrder(isAsc ? "desc" : "asc");
    setEventOrderBy(property);
  };

  const handleEventsChangePage = (_event: unknown, newPage: number) => {
    setEventsPage(newPage);
  };

  const handleEventsChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEventRowsPerPage(parseInt(event.target.value, 10));
    setEventsPage(0);
  };

  const eventToCell = (row: EventInfo) => {
    return (
      <TableRow hover key={row.eid}>
        <TableCell component="th" scope="row">
          {row.eid}
        </TableCell>
        <TableCell align="right">{row.event}</TableCell>
        <TableCell align="right">{row.origin}</TableCell>
        <TableCell align="right">{row.interaction}</TableCell>
        <TableCell align="right">{row.nitems}</TableCell>
        <TableCell align="right">{row.amount}</TableCell>
      </TableRow>
    );
  };

  const emptyEventRows =
    eventsPage > 0
      ? Math.max(0, (1 + eventsPage) * eventRowsPerPage - eventRows.length)
      : 0;

  const handleAttributionsRequestSort = (
    _Attribution: React.MouseEvent<unknown>,
    property: keyof AttributionInfo
  ) => {
    const isAsc = attributionOrderBy === property && attributionOrder === "asc";
    setAttributionOrder(isAsc ? "desc" : "asc");
    setAttributionOrderBy(property);
  };

  const handleAttributionsChangePage = (
    _Attribution: unknown,
    newPage: number
  ) => {
    setAttributionsPage(newPage);
  };

  const handleAttributionsChangeRowsPerPage = (
    Attribution: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttributionRowsPerPage(parseInt(Attribution.target.value, 10));
    setAttributionsPage(0);
  };

  const attributionToCell = (row: AttributionInfo) => {
    return (
      <TableRow hover key={row.aid}>
        <TableCell component="th" scope="row">
          {row.eid}
        </TableCell>
        <TableCell align="right">{row.event}</TableCell>
        <TableCell align="right">{row.adcid}</TableCell>
        <TableCell align="right">{row.adgid}</TableCell>
        <TableCell align="right">{row.adid}</TableCell>
        <TableCell align="right">{row.source}</TableCell>
        <TableCell align="right">{row.medium}</TableCell>
      </TableRow>
    );
  };

  const emptyAttributionRows =
    attributionsPage > 0
      ? Math.max(
          0,
          (1 + attributionsPage) * attributionRowsPerPage -
            attributionRows.length
        )
      : 0;

  return (
    <div>
      <div className="centeredn">
        <Box sx={{ width: "100%" }}>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Status
            </Typography>
            <div className="hbox">
              <Autocomplete
                disablePortal
                id="combo-stores"
                options={stores}
                sx={{ width: "85%" }}
                getOptionLabel={(option) => qualifiedStoreName(option)}
                renderInput={(params) => (
                  <TextField {...params} label="Store" />
                )}
                style={defaultStyle}
                // value={selectedStore}
                // onChange={(_event: any, newValue: Store | null) => setSelectedStore(newValue)}
                inputValue={selectedStoreName}
                onInputChange={(_event, newInputValue) =>
                  setSelectedStoreName(newInputValue)
                }
              />
              <Button
                aria-label="update"
                onClick={handleUpdate}
                startIcon={<RefreshIcon />}
                style={defaultStyle}
                disabled={selectedStoreName.length < 1}
              >
                Update
              </Button>
            </div>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Events
            </Typography>
            <div className="hbox" style={searchBoxStyle}>
              <SearchIcon />
              <Input
                placeholder="Search"
                className="flex flex-1 mx-8"
                disableUnderline
                fullWidth
                value={eventSearchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={(event: {
                  target: { value: SetStateAction<string> };
                }) => setEventSearchText(event.target.value)}
                style={searchInputStyle}
              />
            </div>
            {createTable<EventInfo>(
              eventHeadCells,
              eventOrder,
              eventOrderBy,
              eventRows,
              emptyEventRows,
              eventsPage,
              eventRowsPerPage,
              eventToCell,
              handleEventsRequestSort,
              handleEventsChangePage,
              handleEventsChangeRowsPerPage
            )}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Attributions
            </Typography>
            <div className="hbox" style={searchBoxStyle}>
              <SearchIcon />
              <Input
                placeholder="Search"
                className="flex flex-1 mx-8"
                disableUnderline
                fullWidth
                value={attributionSearchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={(event: {
                  target: { value: SetStateAction<string> };
                }) => setAttributionSearchText(event.target.value)}
                style={searchInputStyle}
              />
            </div>
            {createTable<AttributionInfo>(
              attributionHeadCells,
              attributionOrder,
              attributionOrderBy,
              attributionRows,
              emptyAttributionRows,
              attributionsPage,
              attributionRowsPerPage,
              attributionToCell,
              handleAttributionsRequestSort,
              handleAttributionsChangePage,
              handleAttributionsChangeRowsPerPage
            )}
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default Status;
