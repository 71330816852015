import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Approval,
  ApprovalCreateRequest,
  ApprovalCreateResponse,
  ApprovalListRequest,
  ApprovalDeleteRequest,
  ApprovalDeleteResponse,
  APIError,
  ApprovalDeleteAcidRequest,
} from "../backendTypes";

const initialState = {
  approvals: [] as Array<Approval>,
  approvalError: "",
};

export const listApprovals = createAsyncThunk<
  // Return type of the payload creator
  Array<Approval>,
  // First argument to the payload creator
  ApprovalListRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "approvals/list",
  // Declare the type your function argument here:
  async (requestData: ApprovalListRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/approval/list`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result.approvals as Array<Approval>;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const createApproval = createAsyncThunk<
  // Return type of the payload creator
  ApprovalCreateResponse,
  // First argument to the payload creator
  ApprovalCreateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "approvals/create",
  // Declare the type your function argument here:
  async (requestData: ApprovalCreateRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/approval/create`,
        {
          acid: requestData.acid,
          scope: requestData.scope,
          rsid: requestData.rsid,
          rstype: requestData.rstype,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as ApprovalCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const deleteApproval = createAsyncThunk<
  // Return type of the payload creator
  ApprovalDeleteResponse,
  // First argument to the payload creator
  ApprovalDeleteRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "approvals/delete",
  // Declare the type your function argument here:
  async (requestData: ApprovalDeleteRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/approval/delete`,
        {
          asis: requestData.asis,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as ApprovalDeleteResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const deleteApprovalByAccountID = createAsyncThunk<
  // Return type of the payload creator
  ApprovalDeleteResponse,
  // First argument to the payload creator
  ApprovalDeleteAcidRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "approvals/deleteByAccountID",
  // Declare the type your function argument here:
  async (requestData: ApprovalDeleteAcidRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/approval/delete`,
        {
          acid: requestData.acid,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as ApprovalDeleteResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const approvalsSlice = createSlice({
  name: "approvals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listApprovals.fulfilled, (state, { payload }) => {
      state.approvals = payload;
    });
    builder.addCase(listApprovals.rejected, (state, action) => {
      if (action.payload) {
        state.approvalError = action.payload.error;
      } else {
        state.approvalError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(createApproval.rejected, (state, action) => {
      if (action.payload) {
        state.approvalError = action.payload.error;
      } else {
        state.approvalError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(deleteApproval.rejected, (state, action) => {
      if (action.payload) {
        state.approvalError = action.payload.error;
      } else {
        state.approvalError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(deleteApprovalByAccountID.rejected, (state, action) => {
      if (action.payload) {
        state.approvalError = action.payload.error;
      } else {
        state.approvalError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

// export const { login } = approvalsSlice.actions;

export default approvalsSlice.reducer;
