import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  EventInfoListRequest,
  EventInfoListResponse,
  APIError,
} from "../backendTypes";

const initialState = {
  events: {} as EventInfoListResponse,
  eventError: "",
};

export const listEvents = createAsyncThunk<
  // Return type of the payload creator
  EventInfoListResponse,
  // First argument to the payload creator
  EventInfoListRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "event/list",
  // Declare the type your function argument here:
  async (requestData: EventInfoListRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/event/list`,
        {
          csids: requestData.csids,
          interactions: requestData.interactions,
          collect_start: requestData.collect_start,
          collect_end: requestData.collect_end,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as EventInfoListResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listEvents.fulfilled, (state, { payload }) => {
      state.events = payload;
    });
    builder.addCase(listEvents.rejected, (state, action) => {
      if (action.payload) {
        state.eventError = action.payload.error;
      } else {
        state.eventError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

// export const { login } = eventsSlice.actions;

export default eventsSlice.reducer;
