import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Siteref,
  SiterefCreateRequest,
  SiterefCreateResponse,
  SiterefListRequest,
  APIError,
  SiterefDeleteResponse,
  SiterefDeleteRequest,
} from "../backendTypes";

const initialState = {
  siterefs: [] as Array<Siteref>,
  siterefError: "",
};

export const listSiterefs = createAsyncThunk<
  // Return type of the payload creator
  Array<Siteref>,
  // First argument to the payload creator
  SiterefListRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "siterefs/list",
  // Declare the type your function argument here:
  async (requestData: SiterefListRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/siteref/list`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result.siterefs as Array<Siteref>;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const createSiteref = createAsyncThunk<
  // Return type of the payload creator
  SiterefCreateResponse,
  // First argument to the payload creator
  SiterefCreateRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "siterefs/create",
  // Declare the type your function argument here:
  async (requestData: SiterefCreateRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/siteref/create`,
        {
          csid: requestData.csid,
          refid: requestData.refid,
          reftype: requestData.reftype,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as SiterefCreateResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const deleteSiteref = createAsyncThunk<
  // Return type of the payload creator
  SiterefDeleteResponse,
  // First argument to the payload creator
  SiterefDeleteRequest,
  // Types for ThunkAPI
  {
    rejectValue: APIError;
  }
>(
  "siterefs/delete",
  // Declare the type your function argument here:
  async (requestData: SiterefDeleteRequest, { rejectWithValue }) => {
    try {
      // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
      const response = await axios.post(
        `${requestData.backendUrl}/siteref/delete`,
        {
          csrids: requestData.csrids,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": requestData.session,
          },
        }
      );
      if (response.status !== 200) {
        return rejectWithValue({ error: response.data.error } as APIError);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result as SiterefDeleteResponse;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue({
          error: err.response.data.error,
        } as APIError);
      }
      return rejectWithValue({
        error: "Unkown error occured!",
      } as APIError);
    }
  }
);

export const siterefsSlice = createSlice({
  name: "siterefs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listSiterefs.fulfilled, (state, { payload }) => {
      state.siterefs = payload;
    });
    builder.addCase(listSiterefs.rejected, (state, action) => {
      if (action.payload) {
        state.siterefError = action.payload.error;
      } else {
        state.siterefError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(createSiteref.rejected, (state, action) => {
      if (action.payload) {
        state.siterefError = action.payload.error;
      } else {
        state.siterefError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
    builder.addCase(deleteSiteref.rejected, (state, action) => {
      if (action.payload) {
        state.siterefError = action.payload.error;
      } else {
        state.siterefError = action.error.message
          ? action.error.message
          : "unknown error";
      }
    });
  },
});

// export const { login } = siterefsSlice.actions;

export default siterefsSlice.reducer;
